import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { GLOBAL } from './helpers';

@Injectable()
export class FileServicio{
	public url: string;

	constructor(private _http: Http){
		this.url = GLOBAL.url;
	}

	public fileRequest(url_liga: string, params: Array<string>, file: File){
        let _url = this.url;
        return new Promise(function(resolve, reject){
            var formData:any = new FormData();
            var xhr = new XMLHttpRequest();
          
            if(file){
             
                formData.append('image', file[0], file[0].name);
         
                
            }
     
            
            for(let key of params){
              
                let tKey: any = key;
                for(const i in tKey){
                    formData.append(i, key[i]);
               
                }
            }
            
            xhr.onreadystatechange = function(){
                if(xhr.readyState == 4){
                    if(xhr.status == 200){
                        resolve(JSON.parse(xhr.response));
                    } else {
                        reject(xhr.response);
                    }
                }
            }
            
            xhr.open('POST', _url+url_liga, true);
            xhr.send(formData);

        });
    }
    
    public fileRequestAudio(url_liga: string, params: Array<string>, file: File){
        let _url = this.url;
        return new Promise(function(resolve, reject){
            var formData:any = new FormData();
            var xhr = new XMLHttpRequest();
            
            if(file){
                
                formData.append('file', file[0], file[0].name);
                
                
            }
           
            
            for(let key of params){
               
                let tKey: any = key;
                for(const i in tKey){
                    formData.append(i, key[i]);
                    
                }
            }
            
            xhr.onreadystatechange = function(){
                if(xhr.readyState == 4){
                    if(xhr.status == 200){
                        resolve(JSON.parse(xhr.response));
                    } else {
                        reject(xhr.response);
                    }
                }
            }
          
            xhr.open('POST', _url+url_liga, true);
            xhr.send(formData);

        });
    }
    
}
