export class PortafolioModel{
	constructor(
		public usu_id: number,
		public prt_titulo: string,
		public prt_anio: number,
		public prt_materiales: string,
        public prt_info: string,
        public prt_dimensiones : string,
        public prt_imagen : string,
        public prt_orden : number
      
	){}
}
