import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { GLOBAL } from './helpers';
import { equalParamsAndUrlSegments } from '@angular/router/src/router_state';

@Injectable()

export class PerfilService {
    public url: string;
    constructor(private http: Http) {
        this.url = GLOBAL.url;

    }

    public getPerfiles() {
        let headers = new Headers({ 'Content-Type': 'application/json' })
        return this.http.get(this.url + 'perfil/perfiles', { headers: headers })
            .map(res => res.json())
    }

    public getPerfilByID(id) {
        let headers = new Headers({ 'Content-Type': 'application/json' })
        return this.http.get(this.url + 'perfil/perfil/' + id, { headers: headers })
            .map(res => res.json())
    }

    public updatePerfil(datos) {
        let params = JSON.stringify(datos);
        let headers = new Headers({ 'Content-Type': 'application/json' })
        return this.http.post(this.url + 'perfil/perfil-update', params, { headers: headers })
    }
    public btnDelPerfil(id) {
        let params = JSON.stringify(id);
        let headers = new Headers({ 'Content-Type': 'application/json' })
        return this.http.post(this.url + 'perfil/perfil-delete', params, { headers: headers })

    }

    public btnAddPerfil(datos) {

        let params = JSON.stringify(datos);
        let headers = new Headers({ 'Content-Type': 'application/json' })
        return this.http.post(this.url + 'perfil/perfil-add', params, { headers: headers })

    }

    public getUsuarioByID(usu_id) {

        let headers = new Headers({ 'Content-Type': 'application/json' })
        return this.http.get(this.url + 'usuario_sistema/usuario-sistema/' + usu_id, { headers: headers })
            .map(res => res.json())
    }


    public UpdateUsuario(datos) {

        let params = JSON.stringify(datos);
        let headers = new Headers({ 'Content-Type': 'application/json' })
        return this.http.post(this.url + 'usuario_sistema/usuario-sistema-update', params, { headers: headers })
    }

    public btnDelUsuario(id) {

        let params = JSON.stringify(id);
        let headers = new Headers({ 'Content-Type': 'application/json' })
        return this.http.post(this.url + 'usuario_sistema/usuario-sistema-delete', params, { headers: headers })
    }

    public btnAddUsuario(dato) {


        let params = JSON.stringify(dato);
        let headers = new Headers({ 'Content-Type': 'application/json' })
        return this.http.post(this.url + 'usuario_sistema/usuario-sistema-add', params, { headers: headers })
    }

    public getMenuPerfilR(id) {
        let headers = new Headers({ 'Content-Type': 'application/json' })
        return this.http.get(this.url + 'rel_menu_perfil/rel_menu_perfil/' + id, { headers: headers })
            .map(res => res.json())
    }

    /* servicios  cliente usuario */

    public btnUpdateClienteUsuario(dato) {
        let params = JSON.stringify(dato);
        let headers = new Headers({ 'Content-Type': 'application/json' })
        return this.http.post(this.url + 'usuario/usuario-update', params, { headers: headers })
    }

    public btnDelClienteUsuario(id) {
        let params = JSON.stringify(id);
        let headers = new Headers({ 'Content-Type': 'application/json' })
        return this.http.post(this.url + 'usuario/usuario-delete', params, { headers: headers })
    }

    public DeleteFotoUsuario(datos_artista){
        let params = JSON.stringify(datos_artista);
        let headers = new Headers({'Content-Type' : 'application/json'})
        return this.http.post(this.url+'usuario/usuario-deleteportada/'+datos_artista.usu_id,{headers : headers})
    }

    public btnAddClienteUsuario(dato) {
        let params = JSON.stringify(dato);
        let headers = new Headers({ 'Content-Type': 'application/json' })
        return this.http.post(this.url + 'usuario/usuario-add', params, { headers: headers })
    }
    public getWishListUsuario(usu_id) {
     
        let headers = new Headers({ 'Content-Type': 'application/json' })
        return this.http.get(this.url + 'usuario/usuario-wishlist/' + usu_id, { headers: headers })
            .map(res => res.json())
    }
    /*termina  cliente usuario */
    /*Empeiza Servicios Perfil Artista*/
    public getLibroByID(id) {
        let headers = new Headers({ 'Content-Type': 'application/json' })
        return this.http.get(this.url + 'libro/libro/' + id, { headers: headers })
            .map(res => res.json())
    }


    public btnUpdateArtista(datos) {

        let params = JSON.stringify(datos);
        let headers = new Headers({ 'Content-Type': 'application/json' })
        return this.http.post(this.url + 'artista/artista-update', params, { headers: headers })
    }

    public btnDelArtista(dato) {
        let params = JSON.stringify(dato);
        let headers = new Headers({ 'Content-Type': 'application/json' })
        return this.http.post(this.url + 'artista/artista-delete/', params, { headers: headers })
    }
    public btnAddArtista(dato) {
        let params = JSON.stringify(dato);
        let headers = new Headers({ 'Content-Type': 'application/json' })
        return this.http.post(this.url + 'artista/artista-add/', params, { headers: headers })
    }
    /*/termina perfil artista */
    public AccessoMenuPerfil(accesoperfil) {
        let params = JSON.stringify(accesoperfil);
        let headers = new Headers({ 'Content-Type': 'application/json' })
        return this.http.post(this.url + 'perfil/accesoperfilmenu', params, { headers: headers })
    }

    public updateRelMenu(accesoperfil) {
        let params = JSON.stringify(accesoperfil);
        let headers = new Headers({ 'Content-Type': 'application/json' })
        return this.http.post(this.url + 'perfil/accesoperfilmenu-update', params, { headers: headers })
    }




    /*Empieza Crud De Noticias */

    public btnAddNoticia(dato_noticia) {
        let params = JSON.stringify(dato_noticia);
        let headers = new Headers({ 'Content-Type': 'application/json' })
        return this.http.post(this.url + 'noticia/noticia-add', params, { headers: headers })
    }


    public btnUpdateNoticia(dato_noticia) {
        
        let params = JSON.stringify(dato_noticia);
        let headers = new Headers({ 'Content-Type': 'application/json' })
        return this.http.post(this.url + 'noticia/noticia-update', params, { headers: headers })
    }

    public btnDelNoticia(dato) {
        let params = JSON.stringify(dato);
        let headers = new Headers({ 'Content-Type': 'application/json' })
        return this.http.post(this.url + 'noticia/noticia-delete/', params, { headers: headers })
    }
    /*Termina Crud de Noticias*/
    // /*servicos de catalogo */
    public GetLotesConsola() {
        let headers = new Headers({ 'Content-Type': 'application/json' })
        return this.http.get(this.url + 'lote/loteartista', { headers: headers })
            .map(res => res.json())
    }
    public GetLoteArtistaByID(cat_id) {
        let headers = new Headers({ 'Content-Type': 'application/json' })
        return this.http.get(this.url + 'lote/loteartistaid/' + cat_id, { headers: headers })
            .map(res => res.json())
    }
    public btnAddCatalogo(dato) {
        let params = JSON.stringify(dato);
        let headers = new Headers({ 'Content-Type': 'application/json' })
        return this.http.post(this.url + 'catalogo/catalogo-add', params, { headers: headers })
    }
    public btnAddCatalogoLote(datos) {
        let params = JSON.stringify(datos);
        let headers = new Headers({ 'Content-Type': 'application/json' })
        return this.http.post(this.url + 'catalogo_lote/catalogo_lote-add', params, { headers: headers })
    }
    public btnUpdateCatalogoLote(dato, cat_id) {
        let params = { "datos_lote": dato, "cat_id": cat_id };
        let headers = new Headers({ 'Content-Type': 'application/json' })
        return this.http.post(this.url + 'catalogo_lote/catalogo_lote-update', params, { headers: headers })
    }
    public btnDelCatalogo(dato) {
        let params = JSON.stringify(dato);
        let headers = new Headers({ 'Content-Type': 'application/json' })
        return this.http.post(this.url + 'catalogo_lote/catalogo_lote-delete', params, { headers: headers })
    }

    public UpdateCatalogo(dato) {
        let params = JSON.stringify(dato);
        let headers = new Headers({ 'Content-Type': 'application/json' })
        return this.http.post(this.url + 'catalogo/catalogo-update', params, { headers: headers })
    }

    // /* */

    /*CRUD Lote*/
    public btnAddLote(dato) {
        let params = JSON.stringify(dato);
        let headers = new Headers({ 'Content-Type': 'application/json' });
        return this.http.post(this.url + 'lote/lote-add', params, { headers: headers });
    }

    public LoteObra(datos) {
        let params = JSON.stringify(datos);
        let headers = new Headers({ 'Content-Type': 'application/json' });
        return this.http.post(this.url + 'lote_obra/lote_obra-add', params, { headers: headers })
    }
    public UpdateLote(datos) {
        let params = JSON.stringify(datos);
        let headers = new Headers({ 'Content-Type': 'application/json' });
        return this.http.post(this.url + 'lote/lote-update', params, { headers: headers });
    }

    public btnDelLote(dato) {
        let params = JSON.stringify(dato);
        let headers = new Headers({ 'Content-Type': 'application/json' });
        return this.http.post(this.url + 'lote/lote-delete', params, { headers: headers });
    }

    public GetObraLote(lote_id) {
        let headers = new Headers({ 'Content-Type': 'application/json' })
        return this.http.get(this.url + 'obra/obras_lote/' + lote_id, { headers: headers })
            .map(res => res.json())
    }
    public UpdateObraLote(datos, lot_id) {

        let params = { "datos": datos, "lot_id": lot_id };

        let headers = new Headers({ 'Content-Type': 'application/json' });
        return this.http.post(this.url + 'lote_obra/lote_obra-update', params, { headers: headers })
    }
    /* */

    /*Crud de portafolio*/
    public AddPortafolio(datos) {
        let params = JSON.stringify(datos);
        let headers = new Headers({ 'Content-Type': 'application/json' });
        return this.http.post(this.url + 'portafolio/portafolio-add', params, { headers: headers })
    }
    /* Lista de seleccionables de orden*/
    public ActListPortafolio(dato) {
        let params = JSON.stringify(dato);
        let headers = new Headers({ 'Content-Type': 'application/json' });
        return this.http.post(this.url + 'portafolio/portafoliolista-update', params, { headers: headers })
    }

    public UpdatePortafolio(dato) {
        let params = JSON.stringify(dato);
        let headers = new Headers({ 'Content-Type': 'application/json' });
        return this.http.post(this.url + 'portafolio/portafolio-update', params, { headers: headers })
    }

    public btnDelPortafolio(dato) {
        let params = JSON.stringify(dato);
        let headers = new Headers({ 'Content-Type': 'application/json' });
        return this.http.post(this.url + 'portafolio/portafolio-delete', params, { headers: headers })
    }
    /*Termina crud de portafolio*/

    /*tecnica */
    public getCatTecnicas() {
        let headers = new Headers({ 'Content-Type': 'application/json' })
        return this.http.get(this.url + 'cat_tecnica/cat_tecnicas', { headers: headers })
            .map(res => res.json())
    }

    /* crud obra */
    public btnAddObra(datos){
        let params = JSON.stringify(datos);
        let headers = new Headers({ 'Content-Type' : 'application/json' })
        return this.http.post(this.url+'obra/obra-add',params,{headers: headers})
        .map(res => res.json())
    }
    public UpdateObra(datos){
        let params = JSON.stringify(datos);
        let headers = new Headers({ 'Content-Type' : 'application/json' })
        return this.http.post(this.url+'obra/obra-update',params,{headers : headers})
        .map(res => res.json());
    }

    public getImagenObra(obra_id){
       
        let headers = new Headers ({'Content-Type' : 'application/json'})
        return this.http.get(this.url+'obra_imagen/obra_imagen/'+obra_id,{headers : headers})
        .map(res => res.json());
    }

    public btnDelImgObra(datos){
        let params =JSON.stringify(datos)
        let headers = new Headers ({'Content-Type' : 'application/json'})
        return this.http.post(this.url+'obra_imagen/obra_imagen-update',params,{headers : headers})
        .map(res => res.json());
    }

    public ActListObra(datos){
        let params =JSON.stringify(datos)
        let headers = new Headers ({'Content-Type' : 'application/json'})
        return this.http.post(this.url+'obra_imagen/obra_imagen-updateorden',params,{headers : headers})
        .map(res => res.json());
    }
    /*termina crud obra */


    /** ARCHIVO WISHLIST */
    public getWishlist(cat_id){
        let headers = new Headers ({'Content-Type' : 'application/json'})
        return this.http.get(this.url+'wishlist/wishlist/xls/'+cat_id.cat_id,{headers : headers})
        .map(res => res.json());
    }
}

