import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { GLOBAL } from './helpers';

@Injectable()

export class ObraService {
    public url : string;

    constructor(private http: Http){
        this.url = GLOBAL.url;
    } 
    public getObrasByArtista(id_artista){
        let headers = new Headers({'Content-Type' : 'application/json'})
        return this.http.get(this.url+'obra/obras_artista_disp/'+id_artista,{headers : headers})
        .map(res => res.json())
       
    }
    public getObras(){
        let headers = new Headers({'Content-Type' : 'application/json'})
        return this.http.get(this.url+'obra/obras',{headers : headers})
        .map(res => res.json())
    }
    public getObraByID(id_obra){
        let headers = new Headers({'Content-Type' : 'application/json'})
        return this.http.get(this.url+'obra/obra/'+id_obra,{headers : headers})
        .map(res => res.json())
    }
    public AddObra(datos_obra){
        let params = JSON.stringify(datos_obra);
        let headers = new Headers({'Content-Type' : 'application/json'})
        return this.http.post(this.url+'obra/obra-add', params,{headers : headers})

    }
    public UpdateObra(datos_obra){
        let params = JSON.stringify(datos_obra);
        let headers = new Headers({'Content-Type' : 'application/json'})
        return this.http.post(this.url+'obra/obra-update', params,{headers : headers})

    }
}