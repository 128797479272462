import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { GLOBAL } from './helpers';




@Injectable()

export class ConsolaService {
    public url : string;
    constructor(private http: Http){
        this.url = GLOBAL.url;

  }

public getMenu(id_usuario_sistema){
    let headers = new Headers({'Content-Type' : 'application/json'})
    return this.http.get(this.url+'menu/menus/'+id_usuario_sistema,{headers : headers})
    .map(res => res.json())
 }
 public RegisterProyect(){
    return this.http.get('../../assets/configuracion_consola.json')
}
public getMenuPadre(id_usuario_sistema){
    let headers = new Headers({'Content-Type' : 'application/json'})
    return this.http.get(this.url+'menu/menu_padre/'+id_usuario_sistema,{headers : headers})
    .map(res => res.json())
 }

}