export class catalogoModel{
	constructor(
		public cat_id: number,
		public cat_fec_inicio: string,
        public cat_fec_subasta: string,
        public cat_fec_fin: string,
        public cat_titulo: string,
        public cat_not_catalofo : string,
        public cat_not_subasta : string,
        public cat_status : number,
        public cat_update : string

	){}
}
