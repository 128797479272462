export class ObraModel{
	constructor(
        public obra_id : number,
        public obra_titulo: string,
		public obra_anio: number,
		public usu_id: number,
        public obra_materiales: string, 
        public obra_dimensiones: string, 
        public tec_id: number, 
        public obra_info: string,
        public mig_obra: string,
        public mig_tecnica: string,
        public mig_artista: string,
        public obra_status : number
	){}
}
