export function reloadbrowsermenu(){
  
    setTimeout(function () {
        $.AdminBSB.browser.activate();
        $.AdminBSB.leftSideBar.activate();
        $.AdminBSB.rightSideBar.activate();
        $.AdminBSB.navbar.activate();
        $.AdminBSB.dropdownMenu.activate();
        $.AdminBSB.input.activate();
        $.AdminBSB.select.activate();
        $.AdminBSB.search.activate();
    
        $('.page-loader-wrapper').fadeOut(); }, 1000);
}