export class NoticiaModel{
	constructor(
        public not_id: number,
		public usu_id: number,
		public not_foto: string,
        public not_video: string, 
        public not_fec_publicar: string, 
        public not_fec_crear: string, 
        public not_texto: string,
        public not_url: string,
        public not_status: number,
        public not_titulo: string
	){}
}
