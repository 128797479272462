import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { ObservableService } from "./observable.service"; 
import { NgxEditorModule } from 'ngx-editor';
import { routing, appRoutingProviders, appRoutes } from './app.routing';
import { Routes, RouterModule, Router, ActivatedRoute } from '@angular/router';
import { DataTablesModule } from 'angular-datatables';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from '../app/components/login.component';
import { IndexComponent } from '../../src/index.component';
import { ConsolaComponent  } from '../app/components/consola.component';
import { CatalogoComponent  } from '../app/components/catalogo.component';
import { ConsolaDetalleComponent } from '../app/components/consoladetalle.component';
import { HttpClientModule } from '@angular/common/http';
import { SortablejsModule } from 'angular-sortablejs';
import { OwlDateTimeModule, OwlNativeDateTimeModule,OWL_DATE_TIME_FORMATS,OwlDateTimeIntl } from 'ng-pick-datetime';
import { OwlMomentDateTimeModule } from 'ng-pick-datetime-moment/moment-adapter/moment-date-time.module';

export const MY_MOMENT_FORMATS = {
  parseInput: 'l LT',
  fullPickerInput: 'l LT',
  datePickerInput: 'l',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

// here is the default text string
export class DefaultIntl extends OwlDateTimeIntl  {
  /** A label for the up second button (used by screen readers).  */
  setBtnLabel= 'Guardar'
 
};


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    IndexComponent,
    ConsolaComponent,
    CatalogoComponent,
    ConsolaDetalleComponent
 
  ],
  
  imports: [
    BrowserModule,
    DataTablesModule,
    HttpModule,
    routing,
    FormsModule,
    HttpClientModule,
    NgxEditorModule,
    SortablejsModule,
    BrowserAnimationsModule,
    OwlDateTimeModule, 
    OwlMomentDateTimeModule,
    OwlNativeDateTimeModule,
    
    RouterModule.forRoot(appRoutes, {useHash: true})
  ], 
  providers: [appRoutingProviders,ObservableService],
  bootstrap: [AppComponent]
})
export class AppModule { }
