import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { GLOBAL } from './helpers';


@Injectable()

export class AlertaService {
    public url : string;
    constructor(private http: Http){
        this.url = GLOBAL.url;

  }

public getAlerta(){
    let headers = new Headers({'Content-Type' : 'application/json'})
    return this.http.get(this.url+'alerta/alertas',{headers : headers})
    .map(res => res.json())
 }
 public getAlertaByID(id_alerta){
    let headers = new Headers({'Content-Type' : 'application/json'})
    return this.http.get(this.url+'alerta/alerta/'+id_alerta,{headers : headers})
    .map(res => res.json())
 }
 public AddAlerta(datos_alerta){
    let params = JSON.stringify(datos_alerta);
    let headers = new Headers({'Content-Type' : 'application/json'})
    return this.http.post(this.url+'alerta/alerta-add', params,{headers : headers})
    .map(res => res.json())
}
public UpdateAlerta(datos_alerta){
    let params = JSON.stringify(datos_alerta);
    let headers = new Headers({'Content-Type' : 'application/json'})
    return this.http.post(this.url+'alerta/alerta-update', params,{headers : headers})
    .map(res => res.json())
}

public btnDelAlerta(datos_alerta){
    let params = JSON.stringify(datos_alerta);
    let headers = new Headers({'Content-Type' : 'application/json'})
    return this.http.post(this.url+'alerta/alerta-delete', params,{headers : headers})
    .map(res => res.json())
}


}