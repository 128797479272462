import {  AfterViewInit,Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../services/login.service';
import { ConsolaService } from '../services/consola.service';
import * as $ from 'jquery';
import { reloadbrowsermenu } from '../../assets/js/reloanpage'
import { ObservableService } from "../observable.service";

// You add it here

 
// So you can use it like this later:

@Component({
  selector: 'app-consola',
  templateUrl: '../views/consola.html',
  providers: [ConsolaService]
})
export class ConsolaComponent implements OnInit,AfterViewInit {
  title = 'Consola';
  public session;
  public configuracion_consola;
  public loading: boolean;
  public ArrayListadoMenu = new Array;
  public vista_seleccionada;
  public ver_componente;

  constructor(
    private _dataObserv : ObservableService,
    private router: Router,
    private _activatedRoute: ActivatedRoute,
    private consolaService: ConsolaService
  ) {
    this.loading = true;
    this.sessionActiva();
    this.ConfiguracionConsola();
    this.getMenu();
    this.ver_componente = true;
    this._activatedRoute.params.subscribe(params => {
      this.setComponent(params['menu']);
    
    });
  }
  ngOnInit() {
   
    


  }
	ngAfterViewInit() {
 
	}
	
  CerrarSesion() {
    //elimina el sessionStorage
    sessionStorage.removeItem('session');
    sessionStorage.clear();

    this._dataObserv.changeCerrarSession(0);
    this.router.navigate(['/']);
  }
  sessionActiva() {
    this.loading = true;
    //verifica una session activa si no no permite entrar a consola
    this.session = JSON.parse(sessionStorage.getItem('session'));
    if (this.session == null) {
      this.loading = false;
      this.router.navigate(['/']);

    }
  }

  ConfiguracionConsola() {
    //contiene informacion de el footer @MOB-TK 2018
    this.loading = true;
    this.consolaService.RegisterProyect()
      .subscribe(response => {
        var result = response.json()
        this.configuracion_consola = result.configuracion_consola[0];
        this.loading = false;
      })
  }

  getMenu() {
    this.loading = true;
  
    if(this.session == null){
      this.router.navigate(['/']);
    }else{
  
    let id_usuario_sistema = this.session.id_perfiles;
    this.consolaService.getMenu(id_usuario_sistema)
      .subscribe(response => {
        if (response.err == false) {
          let menu_hijo = response.result;
          this.consolaService.getMenuPadre(id_usuario_sistema)
            .subscribe(response => {
              if (response.err == false) {
                let menu_padre = response.result;
               
               for(let key in  menu_padre){
                 var hijos = new Array();
                 for(let i in menu_hijo){
                  
                   if(menu_padre[key].menu_padre == menu_hijo[i].menu_padre){
                      hijos.push( { menu_hijo : menu_hijo[i].caption, menu_href: menu_hijo[i].menu_nombre } );
                   }

                 }
                 
                 this.ArrayListadoMenu.push({ menu_padre : menu_padre[key].menu_padre, menu_hijo : hijos,icon : menu_padre[key].icon } )
               
               }
               reloadbrowsermenu();
              } 
            })
        }
        this.loading = false;
      });
    }
  }
  
  setComponent(menu) {
   // this._dataObserv.changeVistaConsola(menu);
    //this._dataObserv.changeDetalleConsola(menu);
  }

 
}
