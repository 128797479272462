export class LoteModel{
	constructor(
		public lot_id: number,
		public lot_precio: number,
        public lot_titulo: string,
        public usu_id_comprador: number,
        public lot_status: number,
        public lot_update: string,

	){}
}
