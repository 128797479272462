export class ArtistaModel{
	constructor(
		public usu_id: number,
		public per_id: number,
		public usu_tokens: number,
		public usu_nombre: string,
        public usu_display: string,
        public usu_email: string,
        public usu_psw: string,
        public usu_foto: string,
        public art_nombre: string,
        public art_pais: string,
        public art_galeria: string, 
        public art_web_galeria: string,
        public art_web_artista: string,
        public art_foto: string, 
        public art_video: string,
        public art_bio: string, 
        public art_cv: string,
        public usu_status: number,
        public usu_update: string
	){}
}
