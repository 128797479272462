import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { GLOBAL } from './helpers';

@Injectable()

export class LoginService { 
    public url : string;
    constructor(private http: Http){
        this.url = GLOBAL.url;

    }

    public onAccessoSubmit (d){
        let headers = new Headers({'Content-Type' : 'application/json'})
        return this.http.get(this.url+'usuario_sistema/session_username/'+d.username+'/session_nip/'+d.nip, {headers : headers})
        .map(res => res.json())
    }



}




