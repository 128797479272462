import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: 'index.html',
 
})
export class IndexComponent {
    constructor(
       
      ) { }

ngOnInit() {

}
}