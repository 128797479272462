import { Component ,OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ObservableService } from "./observable.service"
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  public sessionAPP = false;
  constructor (
    private _dataObserv : ObservableService,
    private router: Router,
       

  ){
  
  
  }
  ngOnInit(){
    this._dataObserv.LoginSessionMessage.subscribe(res => {
      
      if (res == 1) {
          this.sessionAPP =  true;
      } 
  })
    this._dataObserv.CerrarSessionMessage.subscribe(res => {
      if (res == 0) {
          this.sessionAPP = false;
      } 
  })
   this.sessionActiva();
  }

  sessionActiva(){
    //this.session = JSON.parse(sessionStorage.getItem('session'));
   
    if(JSON.parse(sessionStorage.getItem('session'))){
      this.sessionAPP =  true;
      this.router.navigate(['/consola/inicio']);
    }
  }
}
