export class AlertaModel{
	constructor(
		public ale_id: number,
		public ale_desc: string,
        public ale_fecha: string,
        public ale_status: number,
		public ale_update: string,
		public ale_fecha_date: Date
	){}
}
