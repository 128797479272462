import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { GLOBAL } from './helpers';

@Injectable()

export class PortafolioService {
    public url : string;

    constructor(private http: Http){
        this.url = GLOBAL.url;
    } 

    public getPortafolio(){
        let headers = new Headers({'Content-Type' : 'application/json'})
        return this.http.get(this.url+'portafolio/portafolios',{headers : headers})
        .map(res => res.json())
    }
    public getPortafolioByID(id_portafolio){
        let headers = new Headers({'Content-Type' : 'application/json'})
        return this.http.get(this.url+'portafolio/portafolio/'+id_portafolio,{headers : headers})
        .map(res => res.json())
    }
    public AddPortafolio(datos_portafolio){
        let params = JSON.stringify(datos_portafolio);
        let headers = new Headers({'Content-Type' : 'application/json'})
        return this.http.post(this.url+'portafolio/portafolio-add', params,{headers : headers})

    }
    public UpdatePortafolio(datos_portafolio){
        let params = JSON.stringify(datos_portafolio);
        let headers = new Headers({'Content-Type' : 'application/json'})
        return this.http.post(this.url+'portafolio/portafolio-update', params,{headers : headers})

    }
}