import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { GLOBAL } from './helpers';

@Injectable()

export class NoticiaService {
    public url : string;

    constructor(private http: Http){
        this.url = GLOBAL.url;
    } 

    public getNoticia(){
        let headers = new Headers({'Content-Type' : 'application/json'})
        return this.http.get(this.url+'noticia/noticias',{headers : headers})
        .map(res => res.json())
    }
    public getNoticiaByID(id_noticia){
        let headers = new Headers({'Content-Type' : 'application/json'})
        return this.http.get(this.url+'noticia/noticia/'+id_noticia,{headers : headers})
        .map(res => res.json())
    }
    public AddNoticia(datos_artista){
        let params = JSON.stringify(datos_artista);
        let headers = new Headers({'Content-Type' : 'application/json'})
        return this.http.post(this.url+'noticia/noticia-add', params,{headers : headers})

    }
    public UpdateNoticia(datos_artista){
        let params = JSON.stringify(datos_artista);
        let headers = new Headers({'Content-Type' : 'application/json'})
        return this.http.post(this.url+'noticia/noticia-update', params,{headers : headers})

    }
}