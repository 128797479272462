export class UsuarioClienteModel{
	constructor(
		public usu_id: number,
		public per_id: number,
		public usu_tokens: number,
		public usu_nombre: string,
        public usu_display: string,
        public usu_email: string,
        public usu_psw: string,
        public usu_foto: string,
        public usu_status: number,
        public usu_update: string
	){}
}
