import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';

export class ObservableService {

    private VistaConsolaSource = new BehaviorSubject<any>("");
    public VistaConsolaMessage = this.VistaConsolaSource.asObservable();

    private DetalleConsolaSource = new BehaviorSubject<any>("");
    public DetalleConsolaMessage = this.DetalleConsolaSource.asObservable();



    public CerrarSessionSource =  new BehaviorSubject<any>("");
    public CerrarSessionMessage = this.CerrarSessionSource.asObservable();

    public LoginSessionSource =  new BehaviorSubject<any>("");
    public LoginSessionMessage = this.LoginSessionSource.asObservable();
    constructor(){

    }
    public changeVistaConsola(newvistaID : any){
        this.VistaConsolaSource.next(newvistaID);
    }

    public changeDetalleConsola(newvistaID : any){
        this.DetalleConsolaSource.next(newvistaID);

    }
    public changeLoginSession(newvistaID : any){
        
        this.LoginSessionSource.next(newvistaID);

    }
    public changeCerrarSession(newvistaID : any){
        
        this.CerrarSessionSource.next(newvistaID);

    }
}