import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { GLOBAL } from './helpers';

@Injectable()

export class LoteService {
    public url : string;

    constructor(private http: Http){
        this.url = GLOBAL.url;
    } 

    public getLote(){
        let headers = new Headers({'Content-Type' : 'application/json'})
        return this.http.get(this.url+'lote/lotes',{headers : headers})
        .map(res => res.json())
    }
    public getLoteByID(id_lote){
        let headers = new Headers({'Content-Type' : 'application/json'})
        return this.http.get(this.url+'lote/lote/'+id_lote,{headers : headers})
        .map(res => res.json())
    }
    public AddLote(datos_lote){
        let params = JSON.stringify(datos_lote);
        let headers = new Headers({'Content-Type' : 'application/json'})
        return this.http.post(this.url+'lote/lote-add', params,{headers : headers})

    }
    public UpdateLote(datos_lote){
        let params = JSON.stringify(datos_lote);
        let headers = new Headers({'Content-Type' : 'application/json'})
        return this.http.post(this.url+'lote/lote-update', params,{headers : headers})

    }
    public getLoteByWishlist(id_lote){
        let headers = new Headers({'Content-Type' : 'application/json'})
        return this.http.get(this.url+'lote/lotewishlist/'+id_lote,{headers : headers})
        .map(res => res.json())
    } 
}