export class Perfil{
	constructor(
		public id_perfiles: string,
		public descripcion: string,
		public permiso_web: number,
		public permiso_app: number,
		public status_perfil: number,
		public accesso : boolean
	){}
}
