import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { GLOBAL } from './helpers';




@Injectable()

export class UsuarioClienteService {
    public url : string;
    constructor(private http: Http){
        this.url = GLOBAL.url;

  }

public getUsuarioCliente(){
    let headers = new Headers({'Content-Type' : 'application/json'})
    return this.http.get(this.url+'usuario/usuarios',{headers : headers})
    .map(res => res.json())
 }
 public getUsuarioClienteByID(id_usuario_cliente){
    
    let headers = new Headers({'Content-Type' : 'application/json'})
    return this.http.get(this.url+'usuario/usuario/'+id_usuario_cliente,{headers : headers})
    .map(res => res.json())
 }
 public AddUsuarioCliente(datos_cliente){
    let params = JSON.stringify(datos_cliente);
    let headers = new Headers({'Content-Type' : 'application/json'})
    return this.http.post(this.url+'usuario/usuario-add', params,{headers : headers})

}
public UpdateUsuarioCliente(datos_cliente){
    let params = JSON.stringify(datos_cliente);
    let headers = new Headers({'Content-Type' : 'application/json'})
    return this.http.post(this.url+'usuario/usuario-update', params,{headers : headers})

}


}