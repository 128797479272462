import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule, ActivatedRoute } from '@angular/router';

//import home
import { LoginComponent } from '../app/components/login.component';
import { ConsolaComponent } from '../app/components/consola.component';
import { IndexComponent } from '../index.component';
import { ConsolaDetalleComponent } from '../app/components/consoladetalle.component'
import { CatalogoComponent  } from '../app/components/catalogo.component'

export const appRoutes: Routes = [
    {path: '', component: LoginComponent },
    {path: 'acceso', component: LoginComponent },
    {path: 'consola', component: ConsolaComponent },
    // {path: 'consola/:menu', component: ConsolaComponent },
    {path: 'consola/:menu', component: CatalogoComponent },
    {path: 'consoladetalle/:menu', component: ConsolaDetalleComponent },
    {path: '**', component: LoginComponent },
    
    
  
]; 

export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes); 