import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { GLOBAL } from './helpers';




@Injectable()

export class CatPerfilService {
    public url : string;
    constructor(private http: Http){
        this.url = GLOBAL.url;

  }

public getCat_Perfil(){
    let headers = new Headers({'Content-Type' : 'application/json'})
    return this.http.get(this.url+'cat_perfil/cat_perfiles',{headers : headers})
    .map(res => res.json())
 }

 public getCat_PerfilbyID(ids){
    let headers = new Headers({'Content-Type' : 'application/json'})
    return this.http.get(this.url+'cat_perfil/cat_perfilbyid/'+ids,{headers : headers})
    .map(res => res.json())
 }

 


}