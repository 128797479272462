import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SortablejsOptions } from 'angular-sortablejs';
import { LoginService } from '../services/login.service';
import { NgForm } from '@angular/forms';
import swal from 'sweetalert2';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { interval } from 'rxjs/internal/observable/interval';
import { ObservableService } from "../observable.service";
import { PerfilService } from '../services/perfiles.service';
import { Perfil } from '../models/perfil';
import { UsuarioModel } from '../models/usuariomodel'
import { MenuPerfil } from '../models/menuperfil'
import { categoriasModel } from '../models/categoriasModel'
import { LibroModel } from '../models/librosModel'
import { FileServicio } from '../services/uploadfile.service';
import { GLOBAL } from '../services/helpers'
import { UsuarioClienteService } from '../services/usuariocliente.service';
import { ArtistaService } from '../services/artista.service';
import { UsuarioClienteModel } from '../models/usuarioclienteModel';
import { ArtistaModel } from '../models/artistaModel';
import { CatPerfilService } from '../services/cat_perfil.service';
import { NoticiaService } from '../services/noticias.service';
import { NoticiaModel } from '../models/noticiaModel';
import { catalogoModel } from '../models/catalogoModel';
import { LoteModel } from '../models/loteModel';
import { CatalogoService } from '../services/catalogos.services';
import { INTERNAL_BROWSER_PLATFORM_PROVIDERS } from '@angular/platform-browser/src/browser';
import { LoteService } from '../services/lotes.services';
import { ObraService } from '../services/obra.sevice';
import { PortafolioService } from '../services/portafolio.sevice';
import { PortafolioModel } from '../models/portafolio';
import { ObraModel } from '../models/obraModel';
import { AlertaModel } from '../models/alertaModel';
import { PARAMETERS } from '../../../node_modules/@angular/core/src/util/decorators';
import { AlertaService } from '../services/alerta.service';
import { NotificacionModel } from '../models/notificacionModel';
import { NotificacionService } from '../services/notificacion.service'
import { CatPerfilModel } from '../models/cat_perfilModel';
import * as moment from 'moment';

@Component({
    selector: 'app-consdetalle',
    templateUrl: '../views/consoladetalle.html',
    providers: [PerfilService, FileServicio, UsuarioClienteService, ArtistaService, CatPerfilService, NoticiaService, CatalogoService, LoteService, ObraService, PortafolioService, AlertaService,NotificacionService]
})

export class ConsolaDetalleComponent implements OnInit {


    options: SortablejsOptions = {
        group: 'test'
    };
    public categoriaperfil;
    public loading: boolean;
    public loading_audio: boolean;
    public btn_add_perfil: boolean;
    public btn_del_perfil: boolean;
    public disabled_perfilusuario : boolean;
    public btn_update_perfil: boolean;
    public datos_detalle;
    public catperfil;
    public catartistas;
    public datosPerfil: Perfil;
    public datosUsuario: UsuarioModel;
    public datosCategoriaPerfil : CatPerfilModel;
    public datosLote: LoteModel;
    public menuPerfil: MenuPerfil;
    public PortafolioModel: PortafolioModel;
    public datosUsuarioCliente: UsuarioClienteModel;
    public datosArtista: ArtistaModel;
    public datosNoticia: NoticiaModel;
    public datosCatalogo: catalogoModel;
    public datosPortafolio: PortafolioModel;
    public datosAlerta: AlertaModel;
    public datosNotificacion: NotificacionModel
    public GLOBAL;
    public accion_usuario;
    public AccesoMenuArray = new Array;
    public rutaSelectedDetalle;
    public perfiles_usuario;
    public activar_detalle: boolean;
    public url_imagen;
    public validarboton: boolean = false;
    public imagen;
    public url;
    public url_server;
    public lotesdisponibles = new Array;
    public lotesseleccionados = new Array;
    public obrasdisponibles = new Array;
    public obrasseleccionadas = new Array;
    public portafolio_seleccionado = new Array;
    public artista_selected;
    public miportafolio;
    public artista_nombre_selected;
    public usuariowishlist;
    public catalogo_lotes;
    public datosObra;
    public misobras;
    public cat_tecnicas;
    public obra_id;
    public obra_seleccionada = new Array;
    public wishlistlote;
    public editorConfig = {
        editable: true,
        spellcheck: false,
        height: '20rem',
        minHeight: '10rem',
        placeholder: 'Ingresa el texto',
        translate: 'no',
        toolbar: [
            ["bold", "italic", "underline", "strikeThrough"],
            ["link", "unlink"]
        ]
    };

    constructor(
        private router: Router,
        private notificacionService : NotificacionService,
        private perfilservice: PerfilService,
        private usuarioclienteservice: UsuarioClienteService,
        private artistaservice: ArtistaService,
        private _data: ObservableService,
        private _dataObserv: ObservableService,
        private uploadfileServicio: FileServicio,
        private _activatedRoute: ActivatedRoute,
        private catperfilService: CatPerfilService,
        private noticiaService: NoticiaService,
        private catalogoService: CatalogoService,
        private portafolioService: PortafolioService,
        private alertaService: AlertaService,
        private obraService: ObraService,
        private loteService: LoteService
    ) {

        this._activatedRoute.params.subscribe(params => {
            if (params['menu']) {
                this.rutaSelectedDetalle = params['menu'];
            }
        });
        this._data.DetalleConsolaMessage.subscribe(res => {

            if (res) {

                this.detalleselected(res);
            } else {
                if (this.rutaSelectedDetalle.indexOf("detalle") >= 0) {


                    this.router.navigate(['/consola/' + this.rutaSelectedDetalle.replace("detalle", "")]);
                }
            }
        })
        this.url_server = GLOBAL.url;
        // this.loading = true;
        this.loading_audio = false;
        this.activar_detalle = false;
        this.btn_add_perfil = false;
        this.btn_del_perfil = false;
        this.btn_update_perfil = false;
        this.disabled_perfilusuario = false;
        this.datosNotificacion = new NotificacionModel(0, 0, "", "", 0);
        this.datosPerfil = new Perfil("", "", 0, 0, 0, false);
        this.datosLote = new LoteModel(0, 0, "", 0, 0, "");
        this.datosUsuario = new UsuarioModel(0, 0, "", "", "", 0);
        this.datosArtista = new ArtistaModel(0, 0, 0, '', '', '', '', '', '', '', '', '', '', '', '', '', '', 0, '');
        this.datosUsuarioCliente = new UsuarioClienteModel(0, 0, 0, '', '', '', '', '', 0, '');
        this.datosNoticia = new NoticiaModel(0, 0, '', '', '', '', '', '', 0, '');
        this.datosCatalogo = new catalogoModel(0, "", "", "", "", "", "", 0, "");
        this.datosPortafolio = new PortafolioModel(0, "", 0, "", "", "", "", 0);
        this.datosObra = new ObraModel(0, "", 0, 0, "", "", 0, "", "", "", "", 0);
        this.datosAlerta = new AlertaModel(0, "", "", 0, "",new Date());
        this.datosCategoriaPerfil = new CatPerfilModel(0,"",0);
        this.catperfilService.getCat_PerfilbyID("1,3,4")
            .subscribe(res => {
                if (res) {
                    this.catperfil = res.perfiles;

                }
            });
        this.artistaservice.getArtista().subscribe(res => {
            if (res) {
                this.catartistas = res.artistas;
            }
        })
    }

    ngOnInit() {
        console.log("HOLA")
    }

    detalleselected(d) {

        this.loading = true;

        //  this.activar_detalle = true;

        var elregreso;
        var lascolumnas;
        //this.rutaSelectedDetalle = d;

        if (this.rutaSelectedDetalle == "consola_perfilesdetalle" || this.rutaSelectedDetalle == "consola_perfiles") {

            this.perfilservice.getPerfilByID(d.id_perfiles)
                .subscribe(response => {

                    if (response == undefined) {


                        this.loading = false;
                    } else {
                        this.loading = false;

                        this.perfilservice.getMenuPerfilR(d.id_perfiles)
                            .subscribe(response => {
                                this.menuPerfil = response;

                            })
                        if (d.info == "Add") {

                            this.datosPerfil = new Perfil("", "", 0, 0, 0, false);

                            this.loading = false;
                            this.btn_del_perfil = false;
                            this.btn_update_perfil = false;

                            this.btn_add_perfil = true;

                        } else if (response.result == undefined) {

                        } else if (response.result[0] != null) {

                            this.loading = false;
                            this.btn_update_perfil = true;
                            this.btn_del_perfil = true;
                            this.btn_add_perfil = false;
                            this.datosPerfil = response.result[0];

                            //buscar mnenus
                        }
                    }

                })
        } else if (this.rutaSelectedDetalle == "consola_usuariosdetalle" || this.rutaSelectedDetalle == "consola_usuarios") {
            this.perfilservice.getUsuarioByID(d.id_usuario_sistema)
                .subscribe(response => {

                    if (response == undefined) {
                        this.loading = false;
                    } else {
                        this.loading = false;
                        //gener los perfiles para un nuevo usuario
                        this.perfilservice.getPerfiles()
                            .subscribe(response => {
                                this.perfiles_usuario = response

                            });
                        if (d.info == "Add") {
                            this.datosPerfil = new Perfil("", "", 0, 0, 0, false);
                            this.datosUsuario = new UsuarioModel(0, 0, "", "", "", 0)
                            this.accion_usuario = "Nuevo Usuario"
                            this.loading = false;
                            this.btn_del_perfil = false;
                            this.btn_update_perfil = false;

                            this.btn_add_perfil = true;

                        } else if (response.result == undefined) {
                        } else if (response.result[0] != null) {
                            this.accion_usuario = "Editar Usuario"
                            this.loading = false;
                            this.btn_update_perfil = true;
                            this.btn_del_perfil = true;
                            this.btn_add_perfil = false;
                            this.datosUsuario = response.result[0];

                        }

                    }

                })
        } else if (this.rutaSelectedDetalle == "consola_artistasdetalle" || this.rutaSelectedDetalle == "consola_artistas") {

            this.artistaservice.getArtistaByID(d.usu_id).subscribe(response => {
                if (response == undefined) {
                    this.loading = false;
                } else {
                    this.loading = false;
                    if (d.info == "Add") {
                        this.accion_usuario = "Nuevo Artista"
                        this.datosArtista = new ArtistaModel(0, 0, 0, '', '', '', '', '', '', '', '', '', '', '', '', '', '', 0, '')
                        this.loading = false;
                        this.btn_del_perfil = false;
                        this.btn_update_perfil = false;
                        this.btn_add_perfil = true;
                    } else if (response.artista[0] != null) {
                        this.accion_usuario = "Editar Artista"
                        this.loading = false;
                        this.btn_update_perfil = true;
                        this.btn_del_perfil = true;
                        this.btn_add_perfil = false;
                        this.datosArtista = response.artista[0];
                        this.url_imagen = GLOBAL.url + this.datosArtista.art_foto;

                    }
                }
            })

        } else if (this.rutaSelectedDetalle == "consola_clientesdetalle" || this.rutaSelectedDetalle == "consola_clientes") {

            this.usuarioclienteservice.getUsuarioClienteByID(d.usu_id).subscribe(response => {

                if (response == undefined) {
                    this.loading = false;
                } else {
                    this.loading = false;
                    if (d.info == "Add") {
                        this.accion_usuario = "Nuevo Usuario"
                        this.datosUsuarioCliente = new UsuarioClienteModel(0, 0, 0, '', '', '', '', '', 0, '')
                        this.loading = false;
                        this.btn_del_perfil = false;
                        this.btn_update_perfil = false;
                        this.btn_add_perfil = true;
                    } else if (response.usuario[0].usu_id != null) {
                        this.accion_usuario = "Editar Usuario"
                        this.catperfilService.getCat_Perfil()
                        .subscribe(response => {
                            this.catperfil = response.perfiles;

                        });

                        this.loading = false;
                        this.btn_update_perfil = true;
                        this.btn_del_perfil = true;
                        this.btn_add_perfil = false;
                        this.datosUsuarioCliente = response.usuario[0];
                        this.url_imagen = GLOBAL.url + this.datosUsuarioCliente.usu_foto;
                        if(this.datosUsuarioCliente.usu_foto) this.validarboton = true;
                        else this.validarboton = false;
                        if(this.datosUsuarioCliente.usu_status == 2){
                            this.disabled_perfilusuario = true;
                        }else{
                            this.disabled_perfilusuario = false;
                        }
                        this.perfilservice.getWishListUsuario(d.usu_id)
                            .subscribe(response => {
                                this.usuariowishlist = response.whislist;


                            });

                    }
                }
            })

        } else if (this.rutaSelectedDetalle == "consola_catalogosdetalle" || this.rutaSelectedDetalle == "consola_catalogos") {
            this.catalogoService.getCatalagoByID(d.cat_id).subscribe(response => {
                if (response == undefined) {
                    this.loading = false;
                } else {

                    this.loading = false;
                    if (d.info == "Add") {
                        this.accion_usuario = "Nuevo Catalogo"
                        this.datosCatalogo = new catalogoModel(0, "", "", "", "", "", "", 0, "");
                        this.loading = false;
                        this.btn_del_perfil = false;
                        this.btn_update_perfil = false;
                        this.btn_add_perfil = true;
                    } else if (response.catalogo[0] != null) {
                        this.accion_usuario = "Editar Catalogo"
                        this.loading = false;
                        this.btn_update_perfil = true;
                        this.btn_del_perfil = true;
                        this.btn_add_perfil = false;
                        this.datosCatalogo = response.catalogo[0];
                        this.perfilservice.GetLoteArtistaByID(this.datosCatalogo.cat_id)
                            .subscribe(response => {
                                this.lotesseleccionados = response.datos_lote;
                            })
                    }
                }
            })
            this.perfilservice.GetLotesConsola()
                .subscribe(response => {

                    let result = response.loteArtista
                    this.lotesdisponibles = result;

                })

        } else if (this.rutaSelectedDetalle == "consola_noticiasdetalle" || this.rutaSelectedDetalle == "consola_noticias") {
            this.noticiaService.getNoticiaByID(d.not_id).subscribe(response => {
                if (response == undefined) {
                    this.loading = false;
                } else {

                    this.loading = false;
                    if (d.info == "Add") {
                        this.accion_usuario = "Nueva Noticia"
                        this.datosNoticia = new NoticiaModel(0, 0, '', '', '', '', '', '', 0, '');
                        this.loading = false;
                        this.btn_del_perfil = false;
                        this.btn_update_perfil = false;
                        this.btn_add_perfil = true;
                    } else if (response.noticia[0].usu_id != null) {
                        this.accion_usuario = "Editar Noticia"
                        this.loading = false;
                        this.btn_update_perfil = true;
                        this.btn_del_perfil = true;
                        this.btn_add_perfil = false;
                        this.datosNoticia = response.noticia[0];
                        this.url_imagen = GLOBAL.url + this.datosNoticia.not_foto;
                    }
                }
            });

        } else if (this.rutaSelectedDetalle == "consola_lotesdetalle" || this.rutaSelectedDetalle == "consola_lotesdetalle") {
            this.loteService.getLoteByID(d.lot_id).subscribe(response => {
                if (response == undefined) {
                    this.loading = false;
                } else {
                    this.loading = false;
                    if (d.info == "Add") {
                        this.accion_usuario = "Nuevo Lote"
                        this.datosLote = new LoteModel(0, 0, '', 0, 0, '');
                        this.loading = false;
                        this.btn_del_perfil = false;
                        this.btn_update_perfil = false;
                        this.btn_add_perfil = true;

                    } else if (response.lote[0] != null) {
                        this.accion_usuario = "Editar Lote"
                        this.loading = false;
                        this.btn_update_perfil = true;
                        this.btn_del_perfil = true;
                        this.btn_add_perfil = false;
                        this.datosLote = response.lote[0];

                        this.perfilservice.GetObraLote(response.lote[0].lot_id)
                            .subscribe(response => {
                                this.obrasseleccionadas = response.loteobras;
                                if (this.obrasseleccionadas.length > 0) {

                                    this.artista_selected = this.obrasseleccionadas[0].usu_id;
                                    this.obraService.getObrasByArtista(this.artista_selected)
                                        .subscribe(response => {
                                            this.obrasdisponibles = response.obras;
                                        });

                                }
                            })
                        this.loteService.getLoteByWishlist(response.lote[0].lot_id)
                            .subscribe(response => {

                                this.wishlistlote = response.datos_usuario;
                            })
                    }
                }
            });
        } else if (this.rutaSelectedDetalle == "consola_portafoliodetalle") {

            this.artista_selected = d.usu_id;
            this.artista_nombre_selected = d.art_nombre;
            this.portafolioService.getPortafolioByID(d.usu_id).subscribe(response => {
                this.portafolio_seleccionado = response.portafolio;
                this.miportafolio = response.portafolio;

                if (response == undefined) {
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.url_imagen = GLOBAL.url;
                    this.accion_usuario = "Portafolio"
                    this.datosPortafolio = new PortafolioModel(0, "", 0, "", "", "", "", 0);
                    this.loading = false;



                }
            });
        } else if (this.rutaSelectedDetalle == "consola_obrasdetalle" || this.rutaSelectedDetalle == "consola_obras") {
            this.perfilservice.getCatTecnicas()
                .subscribe(response => {
                    this.cat_tecnicas = response.tecnica;
                })
            this.obraService.getObraByID(d.obra_id).subscribe(response => {
                this.obra_seleccionada = response.obra;
                this.perfilservice.getImagenObra(d.obra_id).subscribe(result => {
                    this.misobras = result.obra_imagen;
                    this.url_imagen = GLOBAL.url;
                    if (response == undefined) {
                        this.loading = false;
                    } else {

                        this.loading = false;
                        if (d.info == "Add") {
                            this.accion_usuario = "Nueva Obra"
                            this.datosObra = new ObraModel(0, "", 0, 0, "", "", 0, "", "", "", "", 0)
                            this.loading = false;
                            this.btn_del_perfil = false;
                            this.btn_update_perfil = false;
                            this.btn_add_perfil = true;
                        } else if (response.obra[0].obra_id != null) {
                            this.accion_usuario = "Editar Obra"
                            this.loading = false;
                            this.btn_update_perfil = true;
                            this.btn_del_perfil = true;
                            this.btn_add_perfil = false;
                            this.datosObra = response.obra[0];


                        }
                    }
                });
            })

        } else if (this.rutaSelectedDetalle == "consola_alertasdetalle" || this.rutaSelectedDetalle == "consola_alertas") {
            this.alertaService.getAlertaByID(d.ale_id).subscribe(response => {

                if (response == undefined) {
                    this.loading = false;
                } else {
                    this.loading = false;
                    if (d.info == "Add") {
                        this.accion_usuario = "Nueva Alerta"
                        this.datosAlerta = new AlertaModel(0, "", "", 0, "", new Date());
                        this.loading = false;
                        this.btn_del_perfil = false;
                        this.btn_update_perfil = false;
                        this.btn_add_perfil = true;
                    } else if (response.alerta[0] != null) {
                        this.accion_usuario = "Editar Alerta"
                        this.loading = false;
                        this.btn_update_perfil = true;
                        this.btn_del_perfil = true;
                        this.btn_add_perfil = false;
                        console.log("datosAlerta----", response.alerta[0]);
                        this.datosAlerta = response.alerta[0];
                        //this.datosAlerta.ale_fecha = moment(new Date(this.datosAlerta.ale_fecha).toUTCString()).format("YYYY-MM-DD");
                        this.datosAlerta.ale_fecha_date = new Date(this.datosAlerta.ale_fecha);
                        //console.log("datosAlerta", this.datosAlerta);
                    }
                }
            });

        } else if (this.rutaSelectedDetalle == "consola_obra_imagendetalle" || this.rutaSelectedDetalle == "consola_obra_imagen") {
            this.perfilservice.getImagenObra(d.obra_id).subscribe(result => {

                this.obra_id = d.obra_id;
                this.accion_usuario = "Obras"
                this.loading = false;
                this.btn_update_perfil = true;
                this.btn_del_perfil = true;
                this.btn_add_perfil = true;
                this.misobras = result.obra_imagen;
                this.url_imagen = GLOBAL.url;
            });
        } else if (this.rutaSelectedDetalle == "consola_push_notificationdetalle" || this.rutaSelectedDetalle == "consola_push_notification") {
            this.loading = false;
            this.btn_update_perfil = true;
            this.btn_del_perfil = true;
            this.btn_add_perfil = true;
            this.catperfilService.getCat_Perfil()
                .subscribe(response => {
                    this.perfiles_usuario = response.perfiles;

                });

        } else {
            this.loading = false;
        }
    }

    /*Botones de perfil */
    UpdatePerfil() {
        this.perfilservice.updatePerfil(this.datosPerfil)
            .subscribe(response => {
                var result = response.json()


                if (result.status == 1) {
                    for (let key in this.menuPerfil) {
                        this.menuPerfil[key].id_perfiles = this.datosPerfil.id_perfiles;
                    }

                    this.perfilservice.updateRelMenu(this.menuPerfil)
                        .subscribe(response => {
                            if (result.status == 1) {
                                this.datosPerfil = new Perfil("", "", 0, 0, 0, false);
                                if (this.rutaSelectedDetalle.indexOf("detalle") >= 0) {
                                    this.router.navigate(['/consola/' + this.rutaSelectedDetalle.replace("detalle", "")]);
                                }
                                swal("Muy Bien", result.descripcion, "success")
                            }
                        })

                } else {
                    swal("Algo Fallo", result.descripcion, "error")
                }

            })

    }
    BtnCancel() {
        if (this.rutaSelectedDetalle.indexOf("detalle") >= 0) {
            this.router.navigate(['/consola/' + this.rutaSelectedDetalle.replace("detalle", "")]);
        }
    }
    btnDelPerfil() {
        this.perfilservice.btnDelPerfil(this.datosPerfil)
            .subscribe(response => {
                var result = response.json()
                if (result.status == 1) {
                    this.datosPerfil = new Perfil("", "", 0, 0, 0, false);
                    if (this.rutaSelectedDetalle.indexOf("detalle") >= 0) {
                        this.router.navigate(['/consola/' + this.rutaSelectedDetalle.replace("detalle", "")]);
                    }
                    swal("Muy Bien", result.descripcion, "success")

                } else {
                    swal("Algo Fallo", result.descripcion, "error")
                }
            })
    }


    btnAddPerfil() {
        this.perfilservice.btnAddPerfil(this.datosPerfil)
            .subscribe(response => {
                var result = response.json()
                if (result.status == 1) {
                    this.perfilservice.AccessoMenuPerfil(this.menuPerfil)
                        .subscribe(response => {
                            var result = response.json()
                            if (!result.err) {
                                this.datosPerfil = new Perfil("", "", 0, 0, 0, false);
                                if (this.rutaSelectedDetalle.indexOf("detalle") >= 0) {
                                    this.router.navigate(['/consola/' + this.rutaSelectedDetalle.replace("detalle", "")]);
                                }
                                swal("Muy Bien", result.descripcion, "success")
                            }
                        })

                } else {
                    swal("Algo Fallo", result.descripcion, "error")
                }
            })

    }

    //Botones usuariio
    UpdateUsuario() {

        this.perfilservice.UpdateUsuario(this.datosUsuario)
            .subscribe(response => {
                var result = response.json()
                if (result.status == 1) {
                    this.datosUsuario = new UsuarioModel(0, 0, "", "", "", 0)
                    if (this.rutaSelectedDetalle.indexOf("detalle") >= 0) {
                        this.router.navigate(['/consola/' + this.rutaSelectedDetalle.replace("detalle", "")]);
                    }
                    swal("Muy Bien", result.descripcion, "success")
                } else {
                    swal("Algo Fallo", result.descripcion, "error")
                }
            })

    }

    btnDelUsuario() {
        this.perfilservice.btnDelUsuario(this.datosUsuario)
            .subscribe(response => {

                var result = response.json()
                if (result.status == 1) {
                    this.datosUsuario = new UsuarioModel(0, 0, "", "", "", 0)
                    if (this.rutaSelectedDetalle.indexOf("detalle") >= 0) {
                        this.router.navigate(['/consola/' + this.rutaSelectedDetalle.replace("detalle", "")]);
                    }
                    swal("Muy Bien", result.descripcion, "success")
                } else {
                    swal("Algo Fallo", result.descripcion, "error")
                }
            })
    }
    btnAddUsuario() {

        if (this.datosUsuario.username == "" || this.datosUsuario.username == undefined) {
            swal("Algo Fallo", "Debe llenar el campo Usuario Nombre", "error")
        } else if (this.datosUsuario.name == "" || this.datosUsuario.name == undefined) {
            swal("Algo Fallo", "Debe llenar el campo Nombre", "error")
        } else if (this.datosUsuario.id_perfiles == 0 || this.datosUsuario.id_perfiles == undefined) {
            swal("Algo Fallo", "Debe seleccionar un perfil", "error")
        } else {
            this.perfilservice.btnAddUsuario(this.datosUsuario)
                .subscribe(response => {
                    var result = response.json()
                    if (result.status == 1) {
                        this.datosUsuario = new UsuarioModel(0, 0, "", "", "", 0)
                        if (this.rutaSelectedDetalle.indexOf("detalle") >= 0) {
                            this.router.navigate(['/consola/' + this.rutaSelectedDetalle.replace("detalle", "")]);
                        }
                        swal("Muy Bien", result.descripcion, "success")
                    } else {
                        swal("Algo Fallo", result.descripcion, "error")
                    }

                })
        }
    }

    /**termina botones de usuario consola */

    /*Inicia el clienteUsuario */

    btnUpdateClienteUsuario() {
        if (this.datosUsuarioCliente.per_id == 0 || this.datosUsuarioCliente.per_id == undefined) {
            swal("Algo Fallo", "Debe llenar el campo tipo de usuario", "error");
        } else if (this.datosUsuarioCliente.usu_display == "" || this.datosUsuarioCliente.usu_display == undefined) {
            swal("Algo Fallo", "Debe llenar el campo Nombre para mostrar", "error");
        } else if (this.datosUsuarioCliente.usu_email == "" || this.datosUsuarioCliente.usu_email == undefined) {
            swal("Algo Fallo", "Debe llenar el campo email", "error");
        } else if (this.datosUsuarioCliente.usu_nombre == "" || this.datosUsuarioCliente.usu_nombre == undefined) {
            swal("Algo Fallo", "Debe llenar el campo Nombre", "error");
        } else if (this.datosUsuarioCliente.usu_psw == "" || this.datosUsuarioCliente == undefined) {
            swal("Algo Fallo", "Debe llenar el campo de Contraseña", "error");
        } else if (this.datosUsuarioCliente.usu_status == 0 || this.datosUsuarioCliente.usu_status == undefined) {
            swal("Algo Fallo", "Debe seleccionar el Estado de el usuario", "error");
        } else if (this.datosUsuarioCliente.usu_tokens < 0 || this.datosUsuarioCliente.usu_tokens == undefined) {
            swal("Algo Fallo", "Debe seleccionar la cantidad de tokens", "error");
        } else {
            this.perfilservice.btnUpdateClienteUsuario(this.datosUsuarioCliente)
                .subscribe(response => {
                    let result = response.json();
                    if (result.valido = 1) {
                        let params = new Array();
                        if (this.imagen != undefined) {

                            this.uploadfileServicio.fileRequest('usuario/usuario-updateportada/' + this.datosUsuarioCliente.usu_id, params, this.imagen)
                                .then(
                                    (result: any) => {
                                        this.imagen = null;

                                    }
                                )
                        }
                        if (this.rutaSelectedDetalle.indexOf("detalle") >= 0) {
                            this.router.navigate(['/consola/' + this.rutaSelectedDetalle.replace("detalle", "")]);
                        }
                        swal("Muy Bien", result.err, "success")
                    } else {
                        swal("Algo Fallo", "No se pudo Actualizar sus datos", "error");
                    }
                })
        }

    }

    btnDelFotoUsuario(){
        Swal.fire({  
            title: '¿Quieres eliminar esta fotografía?',  
            text: 'No podrás recuperar este archivo!',  
            icon: 'warning',  
            showCancelButton: true,  
            confirmButtonText: 'Si',  
            cancelButtonText: 'No'  
          }).then((result) => {  
            if (result.value) { 
                this.perfilservice.DeleteFotoUsuario(this.datosUsuarioCliente)
                .subscribe(response => {
                    let result = response.json()
                    if(result.valido == 1){
                        Swal.fire(  
                          '¡Borrado!',  
                          'Tu fotografía ha sido eliminada con éxito',  
                          'success'  
                        )
                        this.router.navigate(['/consola/' + this.rutaSelectedDetalle.replace("detalle", "")]);
                    } else {
                        Swal.fire(  
                            '¡Ha ocurrido un error!',  
                            'No se ha completado la instrucción',  
                            'error'  
                          ) 
                    }
                })
            } else if (result.dismiss === Swal.DismissReason.cancel) {  
              Swal.fire(  
                'Cancelado',  
                'Se ha cancelado la eliminación de la fotografía',  
                'error'  
              )  
            }  
          })
    }

    btnDelClienteUsuario() {
        this.perfilservice.btnDelClienteUsuario(this.datosUsuarioCliente)
            .subscribe(response => {
                let result = response.json();
                if (result.valido = 1) {
                    if (this.rutaSelectedDetalle.indexOf("detalle") >= 0) {
                        this.router.navigate(['/consola/' + this.rutaSelectedDetalle.replace("detalle", "")]);
                    }
                    swal("Muy Bien", result.err, "success")
                } else {
                    swal("Error ", result.err, "error")
                }
            })
    }
    btnAddClienteUsuario() {
        if (this.datosUsuarioCliente.per_id == 0 || this.datosUsuarioCliente.per_id == undefined) {
            swal("Algo Fallo", "Debe llenar el campo tipo de usuario", "error");
        } else if (this.datosUsuarioCliente.usu_display == "" || this.datosUsuarioCliente.usu_display == undefined) {
            swal("Algo Fallo", "Debe llenar el campo Nombre para mostrar", "error");
        } else if (this.datosUsuarioCliente.usu_email == "" || this.datosUsuarioCliente.usu_email == undefined) {
            swal("Algo Fallo", "Debe llenar el campo email", "error");
            //} else if (this.imagen == null || this.imagen == "" || this.imagen == undefined) {
            //    swal("Algo Fallo", "Debe subir una foto de perfil", "error");
        } else if (this.datosUsuarioCliente.usu_nombre == "" || this.datosUsuarioCliente.usu_nombre == undefined) {
            swal("Algo Fallo", "Debe llenar el campo Nombre", "error");
        } else if (this.datosUsuarioCliente.usu_psw == "" || this.datosUsuarioCliente == undefined) {
            swal("Algo Fallo", "Debe llenar el campo de Contraseña", "error");
        } else if (this.datosUsuarioCliente.usu_status == 0 || this.datosUsuarioCliente.usu_status == undefined) {
            swal("Algo Fallo", "Debe seleccionar el Estado de el usuario", "error");
        } else if (this.datosUsuarioCliente.usu_tokens < 0 || this.datosUsuarioCliente.usu_tokens == undefined) {
            swal("Algo Fallo", "Debe seleccionar la cantidad de tokens", "error");
        } else if (this.datosUsuarioCliente.usu_email != undefined || this.datosUsuarioCliente.usu_email != "") {
                this.loading = true;
                this.perfilservice.btnAddClienteUsuario(this.datosUsuarioCliente)
                    .subscribe(response => {
                        let result = response.json();
                        if (result.valido == 1) {
                            let params = new Array();
                            if (this.imagen != undefined) {

                                this.uploadfileServicio.fileRequest('usuario/usuario-addportada/' + result.usu_id, params, this.imagen)
                                    .then(
                                        (result: any) => {
                                            this.imagen = null;
                                            if (this.rutaSelectedDetalle.indexOf("detalle") >= 0) {
                                                this.router.navigate(['/consola/' + this.rutaSelectedDetalle.replace("detalle", "")]);
                                            }
                                        }
                                    )
                            }
                            this.loading = false;
                            swal("Muy Bien", result.err, "success")
                        } else {
                            swal("Algo Fallo", result.err, "error");
                            this.loading = false;
                        }
                    })

        }
    }

    valorstatus($event){

        if($event.target.value ==2){
            this.disabled_perfilusuario = true;
        }else{
            this.disabled_perfilusuario = false;
        }
    }
    /*Termina clienteUsuario */


    /*Incia Detalle Artista*/

    btnAddArtista() {

        if (this.datosArtista.usu_status == undefined || this.datosArtista.usu_status == 0) {
            swal("Algo Fallo", "Debe llenar el campo Estado", "error");
        } else if (this.datosArtista.art_nombre == "" || this.datosArtista.art_nombre == undefined) {
            swal("Algo Fallo", "Debe llenar el campo Nombre", "error");
        } else if (this.datosArtista.art_pais == "" || this.datosArtista.art_pais == undefined) {
            swal("Algo Fallo", "Debe llenar el campo Pais", "error");
            //} else if (this.datosArtista.art_galeria == "" || this.datosArtista.art_galeria == undefined) {
            //    swal("Algo Fallo", "Debe llenar el campo Galeria", "error");
            //} else if (this.datosArtista.art_web_galeria == "" || this.datosArtista.art_web_galeria == undefined) {
            //    swal("Algo Fallo", "Debe llenar el campo Pagina Web", "error");
            //} else if (this.datosArtista.art_web_artista == "" || this.datosArtista.art_web_artista == undefined) {
            //    swal("Algo Fallo", "Debe llenar el campo Pagina Web Artista", "error");
            //} else if (this.datosArtista.art_video == "" || this.datosArtista.art_video == undefined) {
            //    swal("Algo Fallo", "Debe llenar el campo Video", "error");
            //} else if (this.datosArtista.art_bio == "" || this.datosArtista.art_bio == undefined) {
            //    swal("Algo Fallo", "Debe llenar el campo Bio", "error");
            //} else if (this.datosArtista.art_cv == undefined || this.datosArtista.art_cv == "") {
            //    swal("Algo Fallo", "Debe llenar el campo CV", "error");
            //} else if (this.imagen == null || this.imagen == "" || this.imagen == undefined) {
            //    swal("Algo Fallo", "Debe subir una foto de perfil", "error");
        } else {
            this.loading = true;
            this.perfilservice.btnAddArtista(this.datosArtista)
                .subscribe(response => {
                    let result = response.json();
                    if (result.valido == 1) {
                        let params = new Array();
                        if (this.imagen != undefined) {

                            this.uploadfileServicio.fileRequest('artista/artista-addportada/' + result.artista.usu_id, params, this.imagen)
                                .then(
                                    (result: any) => {
                                        this.imagen = null;
                                        if (this.rutaSelectedDetalle.indexOf("detalle") >= 0) {
                                            this.router.navigate(['/consola/' + this.rutaSelectedDetalle.replace("detalle", "")]);
                                        }
                                    }
                                )
                        }
                        swal("Muy Bien", result.err, "success")
                        this.loading = false;
                    } else {
                        swal("Algo Fallo", result.err, "error");
                        this.loading = false;
                    }

                })
        }

    }
    UpdateArtista() {

        if (this.datosArtista.usu_status == undefined || this.datosArtista.usu_status == 0) {
            swal("Algo Fallo", "Debe llenar el campo Estado", "error");
        } else if (this.datosArtista.art_nombre == "" || this.datosArtista.art_nombre == undefined) {
            swal("Algo Fallo", "Debe llenar el campo Nombre", "error");
        } else if (this.datosArtista.art_pais == "" || this.datosArtista.art_pais == undefined) {
            swal("Algo Fallo", "Debe llenar el campo Pais", "error");
            //} else if (this.datosArtista.art_galeria == "" || this.datosArtista.art_galeria == undefined) {
            //    swal("Algo Fallo", "Debe llenar el campo Galeria", "error");
            //} else if (this.datosArtista.art_web_galeria == "" || this.datosArtista.art_web_galeria == undefined) {
            //    swal("Algo Fallo", "Debe llenar el campo Pagina Web", "error");
            //} else if (this.datosArtista.art_web_artista == "" || this.datosArtista.art_web_artista == undefined) {
            //    swal("Algo Fallo", "Debe llenar el campo Pagina Web Artista", "error");
            //} else if (this.datosArtista.art_video == "" || this.datosArtista.art_video == undefined) {
            //    swal("Algo Fallo", "Debe llenar el campo Video", "error");
            //} else if (this.datosArtista.art_bio == "" || this.datosArtista.art_bio == undefined) {
            //    swal("Algo Fallo", "Debe llenar el campo Bio", "error");
            //} else if (this.datosArtista.art_cv == undefined || this.datosArtista.art_cv == "") {
            //    swal("Algo Fallo", "Debe llenar el campo CV", "error");
        } else {
            console.log(this.datosArtista);
            this.perfilservice.btnUpdateArtista(this.datosArtista)
                .subscribe(response => {
                    let result = response.json();
                    if (result.valido == 1) {
                        let params = new Array();
                        if (this.imagen != undefined) {

                            this.uploadfileServicio.fileRequest('artista/artista-addportada/' + result.artista.usu_id, params, this.imagen)
                                .then(
                                    (result: any) => {
                                        this.imagen = null;
                                        if (this.rutaSelectedDetalle.indexOf("detalle") >= 0) {
                                            this.router.navigate(['/consola/' + this.rutaSelectedDetalle.replace("detalle", "")]);
                                        }
                                    }
                                )
                        }
                        if (this.rutaSelectedDetalle.indexOf("detalle") >= 0) {
                            this.router.navigate(['/consola/' + this.rutaSelectedDetalle.replace("detalle", "")]);
                        }
                        swal("Muy Bien", result.err, "success")
                    } else {
                        swal("Algo Fallo", result.err, "error");
                    }

                })
        }

    }

    btnDelArtista() {
        this.perfilservice.btnDelArtista(this.datosArtista)
            .subscribe(response => {
                let result = response.json();
                if (result.valido = 1) {
                    if (this.rutaSelectedDetalle.indexOf("detalle") >= 0) {
                        this.router.navigate(['/consola/' + this.rutaSelectedDetalle.replace("detalle", "")]);
                    }
                    swal("Muy Bien", result.err, "success")
                } else {
                    swal("Error ", result.err, "error")
                }
            })
    }
    /* Termina Consola Artista detalle */
    /*Empieza CRUD de Noticia */

    btnAddNoticia() {
        if (this.datosNoticia.not_status == 0 || this.datosNoticia.not_status == undefined) {
            swal("Algo Fallo", "Debe llenar el Estatus", "error");
        } else if (this.datosNoticia.not_fec_publicar == "" || this.datosNoticia.not_fec_publicar == undefined) {
            swal("Algo Fallo", "Debe seleccionar la Fecha a Publicar", "error");
        } else if (this.datosNoticia.not_titulo == "" || this.datosNoticia.not_titulo == undefined) {
            swal("Algo Fallo", "Debe llenar el Titulo de la Noticia", "error");
        } else if (this.datosNoticia.not_texto == "" || this.datosNoticia.not_texto == undefined) {
            swal("Algo Fallo", "Debe llenar el campo Descripción", "error");
            //} else if (this.datosNoticia.usu_id == 0 || this.datosNoticia.usu_id == undefined) {
            //    swal("Algo Fallo", "Debe llenar el campo Artista", "error");
            //} else if (this.datosNoticia.not_video == "" || this.datosNoticia.not_video == undefined) {
            //    swal("Algo Fallo", "Debe llenar el campo Video", "error");
            //} else if (this.datosNoticia.not_url == "" || this.datosNoticia.not_url == undefined) {
            //    swal("Algo Fallo", "Debe llenar el campo Url", "error");
            //} else if (this.imagen == null || this.imagen == "" || this.imagen == undefined) {
            //    swal("Algo Fallo", "Debe Subir una Imagen", "error");
        } else {
            this.perfilservice.btnAddNoticia(this.datosNoticia)
                .subscribe(response => {
                    let result = response.json();
                    if (result.valido == 1) {
                        let params = new Array();
                        if (this.imagen != undefined) {

                            this.uploadfileServicio.fileRequest('noticia/noticia-addportada/' + result.datos_noticia.not_id, params, this.imagen)
                                .then(
                                    (result: any) => {
                                        this.imagen = null;
                                        if (this.rutaSelectedDetalle.indexOf("detalle") >= 0) {
                                            this.router.navigate(['/consola/' + this.rutaSelectedDetalle.replace("detalle", "")]);
                                        }
                                    }
                                )
                        }
                        swal("Muy Bien", result.err, "success")
                    } else {
                        swal("Algo Fallo", result.err, "error");
                    }

                })
        }
    }



    UpdateNoticia() {
        if (this.datosNoticia.not_status == 0 || this.datosNoticia.not_status == undefined) {
            swal("Algo Fallo", "Debe llenar el Estatus", "error");
        } else if (this.datosNoticia.not_fec_publicar == "" || this.datosNoticia.not_fec_publicar == undefined) {
            swal("Algo Fallo", "Debe seleccionar la Fecha a Publicar", "error");
        } else if (this.datosNoticia.not_titulo == "" || this.datosNoticia.not_titulo == undefined) {
            swal("Algo Fallo", "Debe llenar el Titulo de la Noticia", "error");
        } else if (this.datosNoticia.not_texto == "" || this.datosNoticia.not_texto == undefined) {
            swal("Algo Fallo", "Debe llenar el campo Descripción", "error");
            //} else if (this.datosNoticia.usu_id == 0 || this.datosNoticia.usu_id == undefined) {
            //    swal("Algo Fallo", "Debe llenar el campo Artista", "error");
            //} else if (this.datosNoticia.not_video == "" || this.datosNoticia.not_video == undefined) {
            //    swal("Algo Fallo", "Debe llenar el campo Video", "error");
            //} else if (this.datosNoticia.not_url == "" || this.datosNoticia.not_url == undefined) {
            //    swal("Algo Fallo", "Debe llenar el campo Url", "error");
        } else {
            this.perfilservice.btnUpdateNoticia(this.datosNoticia)
                .subscribe(response => {
                    let result = response.json();
                    if (result.valido == 1) {
                        let params = new Array();
                        if (this.imagen != undefined) {

                            this.uploadfileServicio.fileRequest('noticia/noticia-addportada/' + result.datos_noticia.not_id, params, this.imagen)
                                .then(
                                    (result: any) => {
                                        this.imagen = null;

                                    }
                                )
                        }
                        if (this.rutaSelectedDetalle.indexOf("detalle") >= 0) {
                            this.router.navigate(['/consola/' + this.rutaSelectedDetalle.replace("detalle", "")]);
                        }
                        swal("Muy Bien", result.err, "success")
                    } else {
                        swal("Algo Fallo", result.err, "error");
                    }

                })
        }
    }

    btnDelNoticia() {
        this.perfilservice.btnDelNoticia(this.datosNoticia)
            .subscribe(response => {
                let result = response.json();
                if (result.valido = 1) {
                    if (this.rutaSelectedDetalle.indexOf("detalle") >= 0) {
                        this.router.navigate(['/consola/' + this.rutaSelectedDetalle.replace("detalle", "")]);
                    }
                    swal("Muy Bien", result.err, "success")
                } else {
                    swal("Error ", result.err, "error")
                }
            })
    }
    /* Termian crud de Noticias */


    /* CRUD CATALOGO */
    btnAddCatalogo() {
        if (this.datosCatalogo.cat_fec_inicio == "" || this.datosCatalogo.cat_fec_inicio == undefined) {
            swal("Algo Fallo", "Debe seleccionar la fecha Inicio", "error");
        } else if (this.datosCatalogo.cat_fec_subasta == "" || this.datosCatalogo.cat_fec_inicio == undefined) {
            swal("Algo Fallo", "Debe seleccionar la Fecha de Subasta", "error");
        } else if (this.datosCatalogo.cat_fec_fin == "" || this.datosCatalogo.cat_fec_inicio == undefined) {
            swal("Algo Fallo", "Debe seleccionar la Fecha Fin", "error");
        } else if (this.datosCatalogo.cat_titulo == "" || this.datosCatalogo.cat_titulo == undefined) {
            swal("Algo Fallo", "Debe llenar el Campo Titulo", "error");
        } else if (this.lotesseleccionados.length == 0) {
            swal("Algo Fallo", "Debe tener porlomenos un lote seleccionado", "error");
        } else {
            this.perfilservice.btnAddCatalogo(this.datosCatalogo)
                .subscribe(response => {
                    let result = response.json();
                    if (result.valido = 1) {
                        let cat_id = result.cat_id;
                        for (let key in this.lotesseleccionados) {
                            this.lotesseleccionados[key].cat_id = cat_id;
                        }
                        this.perfilservice.btnAddCatalogoLote(this.lotesseleccionados)
                            .subscribe(response => {
                                let result = response.json();
                                if (result.valido = 1) {
                                    if (this.rutaSelectedDetalle.indexOf("detalle") >= 0) {
                                        this.router.navigate(['/consola/' + this.rutaSelectedDetalle.replace("detalle", "")]);
                                    }
                                    swal("Muy Bien", result.err, "success")
                                }
                            })

                    } else {
                        swal("Error ", result.err, "error");
                    }
                })
        }
    }

    UpdateCatalogo() {
        if (this.datosCatalogo.cat_fec_inicio == "" || this.datosCatalogo.cat_fec_inicio == undefined) {
            swal("Algo Fallo", "Debe seleccionar la fecha Inicio", "error");
        } else if (this.datosCatalogo.cat_fec_subasta == "" || this.datosCatalogo.cat_fec_inicio == undefined) {
            swal("Algo Fallo", "Debe seleccionar la Fecha de Subasta", "error");
        } else if (this.datosCatalogo.cat_fec_fin == "" || this.datosCatalogo.cat_fec_inicio == undefined) {
            swal("Algo Fallo", "Debe seleccionar la Fecha Fin", "error");
        } else if (this.datosCatalogo.cat_titulo == "" || this.datosCatalogo.cat_titulo == undefined) {
            swal("Algo Fallo", "Debe llenar el Campo Titulo", "error");
        } else {
            this.perfilservice.UpdateCatalogo(this.datosCatalogo)
                .subscribe(response => {
                    let result = response.json();

                    if (result.valido = 1) {


                        let cat_id = result.cat_id;
                        this.perfilservice.btnUpdateCatalogoLote(this.lotesseleccionados, cat_id)
                            .subscribe(response => {
                                let result = response.json();
                                if (result.valido = 1) {
                                    if (this.rutaSelectedDetalle.indexOf("detalle") >= 0) {
                                        this.router.navigate(['/consola/' + this.rutaSelectedDetalle.replace("detalle", "")]);
                                    }
                                    swal("Muy Bien", result.err, "success")
                                }
                            })

                    } else {
                        swal("Error ", result.err, "error");
                    }
                })
        }
    }
    btnDelCatalogo() {
        this.perfilservice.btnDelCatalogo(this.datosCatalogo)
            .subscribe(response => {
                let result = response.json()
                if (result.valido == 1) {
                    swal("Muy Bien", result.err, "success");
                    if (this.rutaSelectedDetalle.indexOf("detalle") >= 0) {
                        this.router.navigate(['/consola/' + this.rutaSelectedDetalle.replace("detalle", "")]);
                    }
                } else {
                    swal("Error ", result.err, "error");
                }
            });
    }
    /* Empieza Crud Lote*/


    btnAddLote() {
        if (this.datosLote.lot_precio == 0 || this.datosLote.lot_precio == undefined) {
            swal("Error ", "Debe seleccionar un Precio", "error");
        } else if (this.datosLote.lot_titulo == "" || this.datosLote.lot_titulo == undefined) {
            swal("Error ", "Debe Ingresar Un Titulo", "error");
        } else {

            this.perfilservice.btnAddLote(this.datosLote)
                .subscribe(response => {
                    let result = response.json();

                    if(this.obrasseleccionadas.length > 0){

                        for (let key in this.obrasseleccionadas) {
                            this.obrasseleccionadas[key].lot_id = result.lot_id;
                        }

                        this.perfilservice.LoteObra(this.obrasseleccionadas)
                            .subscribe(response => {
                                let result = response.json();
                                if (result.valido == 1) {
                                    if (this.rutaSelectedDetalle.indexOf("detalle") >= 0) {
                                        this.router.navigate(['/consola/' + this.rutaSelectedDetalle.replace("detalle", "")]);
                                    }
                                    swal("Muy Bien", result.err, "success")
                                } else {
                                    swal("Error", result.err, "error");
                                }
                            })
                    }else{
                        if (this.rutaSelectedDetalle.indexOf("detalle") >= 0) {
                            this.router.navigate(['/consola/' + this.rutaSelectedDetalle.replace("detalle", "")]);
                        }
                        swal("Muy Bien", result.err, "success")
                    }


                })
        }

    }


    UpdateLote() {
        if (this.datosLote.lot_precio == 0 || this.datosLote.lot_precio == undefined) {
            swal("Error ", "Debe seleccionar un Precio", "error");
        } else if (this.datosLote.lot_titulo == "" || this.datosLote.lot_titulo == undefined) {
            swal("Error ", "Debe Ingresar Un Titulo", "error");
        } else {
            this.perfilservice.UpdateLote(this.datosLote)
                .subscribe(response => {
                    let result = response.json();

                    if (result.valido == 1) {

                        this.perfilservice.UpdateObraLote(this.obrasseleccionadas, this.datosLote.lot_id)
                            .subscribe(response => {
                                let result = response.json();
                                if (result.valido == 1) {
                                    swal('Muy Bien', result.err, "success")
                                } else {
                                    swal('Error', result.err, 'error')
                                }
                            })
                    }
                });
        }
    }

    btnDelLote() {
        this.perfilservice.btnDelLote(this.datosLote)
            .subscribe(response => {
                let result = response.json();
                if (result.valido == 1) {
                    if (this.rutaSelectedDetalle.indexOf("detalle") >= 0) {
                        this.router.navigate(['/consola/' + this.rutaSelectedDetalle.replace("detalle", "")]);
                    }
                    swal('Muy Bien', result.err, "success")
                } else {
                    swal('Error', result.err, 'error')
                }
            });
    }
    /*termina crud lote */
    /*Empieza Crud Portafolio*/
    btnAddPortafolio() {
        if (this.datosPortafolio.prt_titulo == "" || this.datosPortafolio.prt_titulo == undefined) {
            swal('Error', "El Campo Titulo esta Vacio", 'error');
        } else if (this.datosPortafolio.prt_anio == 0 || this.datosPortafolio.prt_anio == undefined) {
            swal('Error', "El campo Año esta Vacio", 'error');
        } else if (this.datosPortafolio.prt_materiales == "" || this.datosPortafolio.prt_materiales == undefined) {
            swal('Error', "El campo Materiales esta Vacio", 'error');
            //} else if (this.datosPortafolio.prt_dimensiones == "" || this.datosPortafolio.prt_dimensiones == undefined) {
            //    swal('Error', "El campo Dimensiones esta Vacio", 'error');
            // else if (this.datosPortafolio.prt_info == "" || this.datosPortafolio.prt_info == undefined) {
            //    swal('Error', "El campo Información esta vacio", 'error');
        } else {
            this.datosPortafolio.usu_id = this.artista_selected;

            let prt_orden = this.portafolio_seleccionado;
            var max = 0;
            if (this.portafolio_seleccionado.length > 0) {
                max = this.portafolio_seleccionado[this.portafolio_seleccionado.length - 1].prt_orden;
            }
            max = max + 1;
            this.loading = true;
            this.datosPortafolio.prt_orden = max;
            this.perfilservice.AddPortafolio(this.datosPortafolio)
                .subscribe(response => {
                    let result = response.json();

                    if (result.valido = 1) {
                        let params = new Array();
                        if (this.imagen != undefined) {

                            this.uploadfileServicio.fileRequest('portafolio/portafolio-addportafolio/' + result.prt_id, params, this.imagen)
                                .then(
                                    (result: any) => {
                                        this.portafolioService.getPortafolioByID(this.artista_selected).subscribe(response => {
                                            this.portafolio_seleccionado = response.portafolio;
                                            this.miportafolio = response.portafolio;
                                            this.url_imagen = false;
                                        })
                                        this.datosPortafolio = new PortafolioModel(0, "", 0, "", "", "", "", 0);
                                        swal('Muy Bien', result.err, "success");
                                        this.loading = false;
                                    }
                                )
                        }

                    } else {
                        this.loading = false;
                        swal('Error', result.err, 'error');
                    }
                })
        }
    }
    /*esta funcion trae el evento de click al seleccionar una imagen de la lista y traer sus datos */
    seleccionportafolio(i) {
        this.datosPortafolio = this.miportafolio[i];


    }

    UpdatePortafolio() {
        this.perfilservice.UpdatePortafolio(this.datosPortafolio)
            .subscribe(response => {
                let result = response.json();
                if (result.valido = 1) {
                    let params = new Array();
                    if (this.imagen != undefined) {

                        this.uploadfileServicio.fileRequest('portafolio/portafolio-addportafolio/' + result.datos_portafolio.prt_id, params, this.imagen)
                            .then(
                                (result: any) => {
                                    this.portafolioService.getPortafolioByID(this.artista_selected).subscribe(response => {
                                        this.portafolio_seleccionado = response.portafolio;
                                        this.miportafolio = response.portafolio;
                                        this.imagen = null;
                                    })
                                    this.url_imagen = false;
                                    this.datosPortafolio = new PortafolioModel(0, "", 0, "", "", "", "", 0);
                                    swal('Muy Bien', result.err, "success");
                                }
                            )
                    }
                    this.url_imagen = false;
                    this.datosPortafolio = new PortafolioModel(0, "", 0, "", "", "", "", 0);
                    swal('Muy Bien', result.err, "success");
                } else {
                    swal("Algo Fallo", result.err, "error")
                }
            })
    }

    btnDelPortafolio() {
        this.perfilservice.btnDelPortafolio(this.datosPortafolio)
            .subscribe(response => {
                let result = response.json();
                if (result.valido == 1) {
                    this.portafolioService.getPortafolioByID(this.artista_selected).subscribe(response => {
                        this.portafolio_seleccionado = response.portafolio;
                        this.miportafolio = response.portafolio;
                        this.imagen = null;
                    })
                    this.url_imagen = false;
                    this.datosPortafolio = new PortafolioModel(0, "", 0, "", "", "", "", 0);
                    swal("Muy Bien", result.err, "success");
                } else {
                    swal("Error", result.err, "error")
                }
            })
    }

    /*Termina Crud Portafolio */
    fileChange(fileInput: any, tipo: string) {
        this.imagen = fileInput.target.files;
        let reader = new FileReader();
        reader.onload = (e: any) => {
            this.url_imagen = e.target.result;
        }
        reader.readAsDataURL(fileInput.target.files[0])
    }

    cambiaArtista() {
        //his.artista_selected
        this.obrasseleccionadas = new Array;
        this.obrasdisponibles = new Array;
        this.obraService.getObrasByArtista(this.artista_selected)
            .subscribe(response => {
                this.obrasdisponibles = response.obras;
            });
    }

    ActListPortafolio() {
        this.perfilservice.ActListPortafolio(this.portafolio_seleccionado)
            .subscribe(response => {
                let result = response.json();

                if (result.valido == 1) {

                    swal("My Bien", result.err, "success");
                } else {
                    swal("Fallo", result.err, "error");
                }
            })

    }
    /*Inicia Crud Obras*/

    btnAddObra() {
        if (this.datosObra.obra_titulo == "" || this.datosObra.obra_titulo == undefined) {
            swal("Error", "El campo titulo esta vacio", "error");
        } else if (this.datosObra.obra_anio == "" || this.datosObra.obra_anio == undefined) {
            swal("Error", "El campo Año Obra esta vacio", "error");
        } else if (this.datosObra.obra_materiales == "" || this.datosObra.obra_materiales == undefined) {
            swal("Error", "El campo Materiales esta vacio", "error");
        } else if (this.datosObra.obra_dimensiones == "" || this.datosObra.obra_dimensiones == undefined) {
            swal("Error", "El campo dimensiones esta vacio", "error");
        } else if (this.datosObra.usu_id == "" || this.datosObra.usu_id == undefined) {
            swal("Error", "El campo Artista esta vacio", "error");
            //} else if (this.datosObra.tec_id == "" || this.datosObra.tec_id == undefined) {
            //    swal("Error", "El campo Tecnica esta vacio", "error");
            //} else if (this.datosObra.obra_info == "" || this.datosObra.obra_info == undefined) {
            //    swal("Error", "El campo Información esta vacio", "error");
            // }else if(this.imagen == undefined || this.imagen == null || this.imagen== undefined  ){
            //     swal("Error", "Debe Ingresar una imagen" , "error");
            //
        } else {
            this.perfilservice.btnAddObra(this.datosObra)
                .subscribe(response => {

                    let result = response;
                    if (result.valido == 1) {
                        if (this.rutaSelectedDetalle.indexOf("detalle") >= 0) {
                            this.router.navigate(['/consola/' + this.rutaSelectedDetalle.replace("detalle", "")]);
                        }
                        this.imagen = null;
                        this.imagen = "";
                        swal("Muy Bien", result.err, "success");
                        // let params = new Array();
                        //     this.uploadfileServicio.fileRequest('obra_imagen/obra_imagen-add/'+result.obra_id,params,this.imagen)
                        //     .then(
                        //         (result: any) =>{
                        //             this.imagen = null;

                        //   })
                    }
                })

        }

    }

    UpdateObra() {
        if (this.datosObra.obra_titulo == "" || this.datosObra.obra_titulo == undefined) {
            swal("Error", "El campo titulo esta vacio", "error");
        } else if (this.datosObra.obra_anio == "" || this.datosObra.obra_anio == undefined) {
            swal("Error", "El campo Año Obra esta vacio", "error");
        } else if (this.datosObra.obra_materiales == "" || this.datosObra.obra_materiales == undefined) {
            swal("Error", "El campo Materiales esta vacio", "error");
        } else if (this.datosObra.obra_dimensiones == "" || this.datosObra.obra_dimensiones == undefined) {
            swal("Error", "El campo dimensiones esta vacio", "error");
        } else if (this.datosObra.usu_id == "" || this.datosObra.usu_id == undefined) {
            swal("Error", "El campo Artista esta vacio", "error");
            //} else if (this.datosObra.tec_id == "" || this.datosObra.tec_id == undefined) {
            //    swal("Error", "El campo Tecnica esta vacio", "error");
            //} else if (this.datosObra.obra_info == "" || this.datosObra.obra_info == undefined) {
            //    swal("Error", "El campo Información esta vacio", "error");
        } else {
            this.datosObra.obra_status = 1;
            this.perfilservice.UpdateObra(this.datosObra)
                .subscribe(result => {

                    if (result.valido == 1) {
                        if (this.rutaSelectedDetalle.indexOf("detalle") >= 0) {
                            this.router.navigate(['/consola/' + this.rutaSelectedDetalle.replace("detalle", "")]);
                        }
                        swal("Muy Bien", result.err, "success");
                    }
                });
        }
    }


    btnDelObra() {
        if (this.datosObra.obra_titulo == "" || this.datosObra.obra_titulo == undefined) {
            swal("Error", "El campo titulo esta vacio", "error");
        } else if (this.datosObra.obra_anio == "" || this.datosObra.obra_anio == undefined) {
            swal("Error", "El campo Año Obra esta vacio", "error");
        } else if (this.datosObra.obra_materiales == "" || this.datosObra.obra_materiales == undefined) {
            swal("Error", "El campo Materiales esta vacio", "error");
        } else if (this.datosObra.obra_dimensiones == "" || this.datosObra.obra_dimensiones == undefined) {
            swal("Error", "El campo dimensiones esta vacio", "error");
        } else if (this.datosObra.usu_id == "" || this.datosObra.usu_id == undefined) {
            swal("Error", "El campo Artista esta vacio", "error");
        } else if (this.datosObra.tec_id == "" || this.datosObra.tec_id == undefined) {
            swal("Error", "El campo Tecnica esta vacio", "error");
        } else if (this.datosObra.obra_info == "" || this.datosObra.obra_info == undefined) {
            swal("Error", "El campo Información esta vacio", "error");
        } else {
            this.datosObra.obra_status = 0;
            this.perfilservice.UpdateObra(this.datosObra)
                .subscribe(result => {

                    if (result.valido == 1) {
                        if (this.rutaSelectedDetalle.indexOf("detalle") >= 0) {
                            this.router.navigate(['/consola/' + this.rutaSelectedDetalle.replace("detalle", "")]);
                        }
                        swal("Muy Bien", "se Elimino correctamente", "success");
                    }
                });
        }
    }

    /**/
    /*Obra Imagen */

    btnAddImgObra() {
        this.loading = true;
        if (this.imagen == null || this.imagen == undefined || this.imagen == "") {
            swal("Error", "Debe seleccionar una Imagen", "error")
            this.loading = false;
        } else {
            let params = new Array();
            var max = 0;
            if (this.misobras.length > 0) {
                max = this.misobras[this.misobras.length - 1].oi_orden;
            }
            max = max + 1;

            this.datosObra.oi_orden = max;
            this.datosObra.obra_id = this.obra_id
            this.uploadfileServicio.fileRequest('obra_imagen/obra_imagen-add/' + this.datosObra.oi_orden + '/' + this.datosObra.obra_id, params, this.imagen)
                .then(
                    (result: any) => {
                        this.perfilservice.getImagenObra(this.obra_id).subscribe(result => {
                            this.misobras = result.obra_imagen;
                            this.loading = false;
                        })
                        //limpiar input
                        this.url_imagen = false;
                        var file = $("#archivoObra")
                        file.val("");
                        //file.replaceWith(file.val('').clone(true));

                    })
        }

    }

    btnDelImgObra(i) {

        this.perfilservice.btnDelImgObra(this.misobras[i])
            .subscribe(result => {
                if (result.valido = 1) {
                    this.perfilservice.getImagenObra(this.obra_id).subscribe(result => {
                        this.misobras = result.obra_imagen;
                    })
                }
            })
    }
    ActListObra() {
        this.perfilservice.ActListObra(this.misobras)
            .subscribe(result => {

                if (result.valido == 1) {
                    swal("My Bien", result.err, "success");
                } else {
                    swal("Fallo", result.err, "error");
                }
            })

    }
    /* */

    /* CRUD ALERTA */

    btnUpdateAlerta() {
        if (this.datosAlerta.ale_desc == "" || this.datosAlerta.ale_desc == undefined) {
            swal("Error", "El campo descripción esta vacio", "error");
        } else if (this.datosAlerta.ale_fecha == "" || this.datosAlerta.ale_fecha == undefined) {
            swal("Error", "El campo Fecha esta vacio", "error");
        } else {
            this.datosAlerta.ale_status = 1;

            this.alertaService.UpdateAlerta(this.datosAlerta)
                .subscribe(result => {
                    if (result.valido == 1) {
                        this.datosAlerta = new AlertaModel(0, "", "", 0, "", new Date());
                        if (this.rutaSelectedDetalle.indexOf("detalle") >= 0) {
                            this.router.navigate(['/consola/' + this.rutaSelectedDetalle.replace("detalle", "")]);
                        }
                        swal("Muy Bien", result.err, "success");
                    }
                });
        }
    }

    btnAddAlerta() {
        if (this.datosAlerta.ale_desc == "" || this.datosAlerta.ale_desc == undefined) {
            swal("Error", "El campo descripción esta vacio", "error");
        } else if (this.datosAlerta.ale_fecha == "" || this.datosAlerta.ale_fecha == undefined) {
            swal("Error", "El campo Fecha esta vacio", "error");
        } else {
            this.alertaService.AddAlerta(this.datosAlerta)
                .subscribe(response => {
                    let result = response;
                    if (result.valido == 1) {
                        if (this.rutaSelectedDetalle.indexOf("detalle") >= 0) {
                            this.router.navigate(['/consola/' + this.rutaSelectedDetalle.replace("detalle", "")]);
                        }
                        swal("Muy Bien", result.err, "success");
                    }
                })

        }

    }

    btnDelAlerta(){
        this.alertaService.btnDelAlerta(this.datosAlerta)
        .subscribe(response => {
            let result = response;
            if (result.valido == 1) {
                if (this.rutaSelectedDetalle.indexOf("detalle") >= 0) {
                    this.router.navigate(['/consola/' + this.rutaSelectedDetalle.replace("detalle", "")]);
                }
                swal("Muy Bien", result.err, "success");
            }
        })
    }
    /* */
    //Crud Notificaciones
    AddNotificacion(){
        if(this.datosNotificacion.ntf_desc == "" || this.datosNotificacion.ntf_desc == undefined){
            swal("Error", "Debe ingresar un Mensaje","error")
        }else if(this.datosCategoriaPerfil.per_id == 0 || this.datosCategoriaPerfil.per_id == undefined ){
            swal("Error", "Debe seleccionar una Opcion","error");
        }else{

            this.datosNotificacion.ntf_status = 1;
            this.datosNotificacion.per_id = this.datosCategoriaPerfil.per_id ;
            this.notificacionService.AddNotificacion(this.datosNotificacion)
            .subscribe(result => {
                if(result.valido == 1){
                    swal("Muy Bien", result.err, "success");
                    this.datosCategoriaPerfil = new CatPerfilModel(0,"",0);
                    this.datosNotificacion = new NotificacionModel(0, 0, "", "", 0);
                }else{
                    swal("Error",result.err, "error")
                }
            })
        }
    }

    //Refresh App
    RefreshNotificacion(){
        this.notificacionService.AddNotificacionRefresh()
        .subscribe(result => {
            if(result.valido == 1){
                swal("Muy Bien", result.err, "success");
                this.datosCategoriaPerfil = new CatPerfilModel(0,"",0);
                this.datosNotificacion = new NotificacionModel(0, 0, "", "", 0);
            }else{
                swal("Error",result.err, "error")
            }
        })
    }

    //Paste
    onPaste(e){
        e.preventDefault;
        e.stopPropagation();
        let texto= e.clipboardData.getData('text/plain');
        console.log(texto);
        if (document.queryCommandSupported('insertText')) {
            console.log('insert');
            document.execCommand('insertText', false, texto);
          } else {
            console.log('paste');
            document.execCommand('paste', false, texto);
          }
        return true;
    }

}
