import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { GLOBAL } from './helpers';

@Injectable()

export class NotificacionService { 
    public url : string;
    constructor(private http: Http){
        this.url = GLOBAL.url;

    }

    public AddNotificacion (datos){
    
        let headers = new Headers({'Content-Type' : 'application/json'});
        let params = JSON.stringify(datos);
        return this.http.post(this.url+'notificacion/notificacion-add',params, {headers : headers})
        .map(res => res.json())
    }

    public AddNotificacionRefresh (){
    
        let headers = new Headers({'Content-Type' : 'application/json'});
        return this.http.post(this.url+'notificacion/notificacion-refresh', {headers : headers})
        .map(res => res.json())
    }



}


