import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { GLOBAL } from './helpers';




@Injectable()

export class ArtistaService {
    public url : string;
    constructor(private http: Http){
        this.url = GLOBAL.url;

  }

public getArtista(){
    let headers = new Headers({'Content-Type' : 'application/json'})
    return this.http.get(this.url+'artista/artistas',{headers : headers})
    .map(res => res.json())
 }
 public getArtistaByID(id_artista){
    let headers = new Headers({'Content-Type' : 'application/json'})
    return this.http.get(this.url+'artista/artista/'+id_artista,{headers : headers})
    .map(res => res.json())
 }
 public AddArtista(datos_artista){
    let params = JSON.stringify(datos_artista);
    let headers = new Headers({'Content-Type' : 'application/json'})
    return this.http.post(this.url+'artista/artista-add', params,{headers : headers})

}
public UpdateArtista(datos_artista){
    let params = JSON.stringify(datos_artista);
    let headers = new Headers({'Content-Type' : 'application/json'})
    return this.http.post(this.url+'artista/artista-update', params,{headers : headers})

}

}