import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../services/login.service';
import {NgForm } from '@angular/forms';
import swal from 'sweetalert2';
import { ObservableService } from "../observable.service";
import {interval} from 'rxjs/internal/observable/interval';
@Component({
  selector: 'app-login',
  templateUrl: '../views/login.html',
  providers :[LoginService]
})
export class LoginComponent implements OnInit {
  title = 'Login';
  public nip;
  public username;
  public session; 
  constructor (
    private _dataObserv : ObservableService,
    private router: Router,
    private  loginService : LoginService

  ){
    
    //this.sessionActiva();
   
  }
  ngOnInit(){
   
  }

  onAccessoSubmit(registerForm : NgForm ){
    let acceso = registerForm.value;
    this.loginService.onAccessoSubmit(acceso)
    .subscribe(response => {
      if(response.err == true){
        swal("Oops", response.error+"!", "error")
      }else{
        swal("Muy Bien",response.descripcion,"success")
        this._dataObserv.changeLoginSession(1);
        let key = "session"
        let usuario = JSON.stringify(response.usuario);
        sessionStorage.setItem(key,usuario);
        
       // 
       this.router.navigate(['/consola/inicio']);
       
      }

    
    })
  }

  sessionActiva(){
    this.session = JSON.parse(sessionStorage.getItem('session'));
   
    if(this.session != null){
    
      this.router.navigate(['/consola']);
    }
  }

}
