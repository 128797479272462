export class UsuarioModel{
	constructor(
		public id_usuario_sistema: number,
		public id_perfiles: number,
		public username: string,
		public nip: string,
        public name: string,
        public cliente_id : number,
       
	){}
}
