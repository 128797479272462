import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PerfilService } from '../services/perfiles.service';
import { ConsolaService } from '../services/consola.service';
import * as $ from 'jquery';
import { reloadbrowsermenu } from '../../assets/js/reloanpage'
import { ObservableService } from '../observable.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { GLOBAL } from '../services/helpers';
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { LibroModel } from '../models/librosModel';

// You add it here
@Component({
    selector: 'app-catalogo',
    templateUrl: '../views/catalogo.html',
    providers: [PerfilService]
})

export class CatalogoComponent implements AfterViewInit, OnInit, OnDestroy {
    @ViewChild(DataTableDirective)
    dtElement: DataTableDirective;
    public activar_detalle: boolean;
    dtOptionsPerfiles: DataTables.Settings = {};
    dtOptionsUsuario: DataTables.Settings = {};
    dtOptionsUsuarioCliente: DataTables.Settings = {};
    dtOptionsArtista: DataTables.Settings = {};
    dtOptionsNoticia: DataTables.Settings = {};
    dtOptionsCatalogo: DataTables.Settings = {};
    dtOptionsLotes: DataTables.Settings = {};
    dtOptionsObras: DataTables.Settings = {};
    dtOptionsAlertas: DataTables.Settings = {};
    ArrayLibros: LibroModel[] = [];
    // { emptyTable : 'Ningún dato disponible en esta tabla', search :'Buscar' };

    dtTrigger: Subject<any> = new Subject();
    public rutaSelected = '';
    public url;
    public columnas_perfil;
    public columnas_usuario;
    public columnas_usuariocliente;
    public columnas_artista;
    public columnas_noticia;
    public columnas_catalogo;
    public columnas_lote;
    public columnas_obra;
    public columnas_alerta;
    public urlservice: string;
    public CatalogoLibros;
    public id_cat = 0;
    constructor(
        private router: Router,
        private http: Http,
        private perfilservice: PerfilService,
        private _data: ObservableService,
        private _activatedRoute: ActivatedRoute
    ) {
        this.urlservice = GLOBAL.url;
        this._activatedRoute.params.subscribe(params => {
            // this.setComponent(params['menu']);
            this.url = params['menu'];
            this.vista(params['menu']);
           
        });
        this._data.VistaConsolaMessage.subscribe(res => {
            // this.url = res;
            // this.vista(res);
        });

        this.activar_detalle = false;
    }

    ngOnInit() {
        
        //tabla de perfil
        this.columnas_perfil = [{
            title: 'ID',
            data: 'id_perfiles'
        }, {
            title: 'Descripcion',
            data: 'descripcion'
        }];
        //tabla usuarios
        this.columnas_usuario = [{
            title: 'ID',
            data: 'id_usuario_sistema'
        }, {
            title: 'Nombre Usuario',
            data: 'username'
        }, {
            title: ' Nombre',
            data: 'name'
        }];
        //tabla usuariocliente
        this.columnas_usuariocliente = [{
            title: 'Nombre',
            data: 'usu_nombre'
        },
        {
            title: 'Nombre para mostrar',
            data: 'usu_display'
        },
        {
            title: 'Tipo',
            data: 'per_desc'
        },
        {
            title: 'Estado',
            data: 'usu_status'
        }];
        //tabla artistas
        this.columnas_artista = [{
            title: 'Nombre',
            data: 'art_nombre'
        }, {
            title: 'Pais',
            data: 'art_pais'
        }, {
            title: 'Estado',
            data: 'usu_status'
        }, {
            title: 'Acciones',
            data: 'usu_id',
            render: function (data, type, full, meta) {
                return "<button class ='btn btn-info'>Portafolio</button>";
            }
        }];

        //tabla noticia
        this.columnas_noticia = [{
            titile: 'Imagen',
            data: 'not_foto',
            "render": this.getImg
        }, {
            title: 'Título',
            data: 'not_titulo'
        }, {
            title: 'Estado',
            data: 'not_status'
        }, {
            title: 'Fecha',
            data: 'not_fec_publicar'
        }];

        //tabla catalogo
        this.columnas_catalogo = [{
            title: 'Título',
            data: 'cat_titulo'
        },{
            title: 'Acciones',
                data: 'cat_id',
                render: function (data, type, full, meta) {
                    return "<button class ='btn btn-info' (click)='getUrlWishList(cat_id)'>WishList</button>";
                }      
        }
    ]

        //tabla lote
        this.columnas_lote = [{
            title: 'Título',
            data: 'lot_titulo'
        }, {
            title: 'Artista',
            data: 'art_nombre'
        }, {
            title: 'Precio',
            data: 'lot_precio'
        }, {
            title: 'Deseado',
            data: 'deseado'
        }]
        //tabla obras
        this.columnas_obra = [{
            title: 'Título',
            data: 'obra_titulo'
        }, {
            title: 'Año',
            data: 'obra_anio'
        }, {
            title: 'Artista',
            data: 'artista_nombre'
        }
            , {
            title: 'Acciones',
            data: 'obra_id',
            render: function (data, type, full, meta) {
                return "<button class ='btn btn-info'>Fotos</button>";
            }
        }];
        //tabla alertas
        this.columnas_alerta = [{
            title: 'Alerta',
            data: 'ale_desc'
        }, {
            title: 'Fecha',
            data: 'ale_fecha'
        }]
      

        this.TablaCatalogo('', this.columnas_catalogo);
        this.TablaArtista('', this.columnas_artista);
        this.TablaUsuarioCliente('', this.columnas_usuariocliente)
        this.pintatabla('', this.columnas_perfil);
        this.TablaUsuario('', this.columnas_usuario);
        this.TablaNoticia('', this.columnas_noticia);
        // this.TablaCatalogo('',this.columnas_catalogo);
        this.TablaLote('', this.columnas_lote);
        this.TablaObra('', this.columnas_obra);
        this.TablaAlerta('', this.columnas_alerta);
        //this.LibroCategorias();
        //this.TablaArtista('', this.columnas_artista);

    }

    gotoPortafolio(event: any, valor) {
        event.stopPropagation();

    }

    getImg(data, type, full, meta) {
        let urlservice = GLOBAL.url;
        return '<img src="' + urlservice + data + '" width="55px" />';
    }

    ngAfterViewInit() {
    }

    vista(ruta) {
        
        var elregreso;
        this.rutaSelected = ruta;
        if (ruta == 'consola_perfiles') {
            this.pintatabla(elregreso, this.columnas_perfil);
        } else if (ruta == 'consola_usuarios') {
            this.TablaUsuario(elregreso, this.columnas_usuario)
        } else if (ruta == 'consola_categoria') {
            this.TablaUsuarioCliente('', this.columnas_usuariocliente)

        } else if (ruta == 'consola_libro') {
            this.TablaUsuarioCliente('', this.columnas_usuariocliente)

        } else if (ruta == 'consola_catalogos') {
            this.TablaCatalogo('', this.columnas_catalogo);
        } else if (ruta == 'consola_lotes') {
            this.TablaLote('', this.columnas_lote);
        } else if (ruta == 'consola_noticias') {
            this.TablaNoticia('', this.columnas_noticia);
        } else if (ruta == 'consola_push_notification'){
            let info = ({sin_registro :""});

            this._data.changeDetalleConsola(info);
            this.router.navigate(['consoladetalle/consola_push_notificationdetalle']);
        }


    }
   
      

    pintatabla(elregreso, lascolumnas) {


        this.dtOptionsPerfiles = {
            // data: elregreso  ,
            //language: this.Spanish,
            language: { url: '../../assets/js/spanish.json' },
            ajax: (dataTablesParameters: any, callback) => {
                let headers = new Headers({ 'Content-Type': 'application/json' })
                this.http.get(this.urlservice + 'perfil/perfiles', { headers: headers })
                    .subscribe(resp => {
                        callback({

                            data: resp.json(),
                        });
                    });
            },

            columns: lascolumnas,

            rowCallback: (row: Node, data: any[] | Object, index: number) => {
                const self = this;
                // Unbind first in order to avoid any duplicate handler
                // (see https://github.com/l-lin/angular-datatables/issues/87)
                $('td', row).unbind('click');
                $('td', row).bind('click', () => {
                    self.someClickHandler(data);
                });
                return row;
            },


        };

    }
    TablaUsuario(elregreso, columnas_usuario) {


        this.dtOptionsUsuario = {
            // data: elregreso  ,
            //language: this.Spanish,
            language: { url: '../../assets/js/spanish.json' },
            ajax: (dataTablesParameters: any, callback) => {
                let headers = new Headers({ 'Content-Type': 'application/json' })
                this.http.get(this.urlservice + 'usuario_sistema/usuarios-sistema', { headers: headers })
                    .subscribe(resp => {

                        callback({

                            data: resp.json().result,
                        });
                    });
            },

            columns: columnas_usuario,

            rowCallback: (row: Node, data: any[] | Object, index: number) => {
                const self = this;
                // Unbind first in order to avoid any duplicate handler
                // (see https://github.com/l-lin/angular-datatables/issues/87)
                $('td', row).unbind('click');
                $('td', row).bind('click', () => {
                    self.someClickHandler(data);
                });
                return row;
            },


        };

    }

    TablaUsuarioCliente(elregreso, columnas_usuariocliente) {
        this.dtOptionsUsuarioCliente = {
            // data: elregreso  ,
            //language: this.Spanish,
            language: { url: '../../assets/js/spanish.json' },
            ajax: (dataTablesParameters: any, callback) => {
                let headers = new Headers({ 'Content-Type': 'application/json' })
                let usuariosc;
                this.http.get(this.urlservice + 'usuario/usuariocliente', { headers: headers })
                    .subscribe(resp => {
                        usuariosc = resp.json().usuariocliente;
                        for (let key in usuariosc) {
                            if (usuariosc[key].usu_status == 1) {
                                usuariosc[key].usu_status = "Activo";
                            } else if (usuariosc[key].usu_status == 2) {
                                usuariosc[key].usu_status = "Inactivo";
                            }
                        }
                        callback({

                            data: usuariosc

                        });
                    });
            },

            columns: columnas_usuariocliente,

            rowCallback: (row: Node, data: any[] | Object, index: number) => {
                const self = this;
                // Unbind first in order to avoid any duplicate handler
                // (see https://github.com/l-lin/angular-datatables/issues/87)
                $('td', row).unbind('click');
                $('td', row).bind('click', () => {
                    self.someClickHandler(data);
                });
                return row;
            },
        }
    }
    TablaArtista(elregreso, columnas_artista) {
        this.dtOptionsArtista = {
            // data: elregreso  ,
            //language: this.Spanish,
            language: { url: '../../assets/js/spanish.json' },
            ajax: (dataTablesParameters: any, callback) => {
                let headers = new Headers({ 'Content-Type': 'application/json' })
                this.http.get(this.urlservice + 'artista/artistas', { headers: headers })
                    .subscribe(resp => {
                        let artistas = resp.json().artistas;
                        for (let key in artistas) {
                            if (artistas[key].usu_status == 1) {
                                artistas[key].usu_status = "Activo";
                            } else if (artistas[key].usu_status == 2) {
                                artistas[key].usu_status = "Inactivo";
                            }
                        }
                        callback({
                            data: artistas
                        });
                    });
            },
            columns: columnas_artista,
            rowCallback: (row: Node, data: any[] | Object, index: number) => {
                const self = this;
                // Unbind first in order to avoid any duplicate handler
                // (see https://github.com/l-lin/angular-datatables/issues/87)
                $('td', row).unbind('click');
                $('td', row).bind('click', () => {
                    self.someClickHandler(data);
                });
                $('td button', row).bind('click', (event) => {
                    event.stopPropagation();
                    self.buttonHandler(data, "portafolio");
                });

                return row;
            },
        }
    }
    TablaNoticia(elregreso, columnas_artista) {
        this.dtOptionsNoticia = {
            language: { url: '../../assets/js/spanish.json' },
            ajax: (dataTablesParameters: any, callback) => {
                let headers = new Headers({ 'Content-Type': 'application/json' })
                this.http.get(this.urlservice + 'noticia/noticias', { headers: headers })
                    .subscribe(resp => {
                        let noticias = resp.json().noticias;
                        for (let key in noticias) {
                            if (noticias[key].not_status == 1) {
                                noticias[key].not_status = "Publicado";
                            } else if (noticias[key].not_status == 2) {
                                noticias[key].not_status = "No Publicado";
                            }
                            noticias[key].not_fec_publicar = noticias[key].not_fec_publicar.substring(0, 10);
                        }
                        callback({
                            data: noticias
                        });
                    });
            },
            columns: columnas_artista,
            rowCallback: (row: Node, data: any[] | Object, index: number) => {
                const self = this;
                // Unbind first in order to avoid any duplicate handler
                // (see https://github.com/l-lin/angular-datatables/issues/87)
                $('td', row).unbind('click');
                $('td', row).bind('click', () => {
                    self.someClickHandler(data);
                });
                return row;
            }
        }
    }
    TablaCatalogo(elregreso, columnas_catalogo) {
        this.dtOptionsCatalogo = {
            language: { url: '../../assets/js/spanish.json' },
            ajax: (dataTablesParameters: any, callback) => {
                let headers = new Headers({ 'Content-Type': 'application/json' })
                this.http.get(this.urlservice + 'catalogo/catalogos', { headers: headers })
                    .subscribe(resp => {
                        let catalogos = resp.json().catalogos;
                        callback({
                            data: catalogos
                        });
                    });
            },
            columns: columnas_catalogo,
            rowCallback: (row: Node, data: any[] | Object, index: number) => {
                const self = this;
                // Unbind first in order to avoid any duplicate handler
                // (see https://github.com/l-lin/angular-datatables/issues/87)
                $('td', row).unbind('click');
                $('td', row).bind('click', () => {
                    self.someClickHandler(data);
                });
                $('td button', row).bind('click', (event) => {
                    event.stopPropagation();
                    self.getUrlWishList(data);
                });
                return row;
            }
        }
    }
    TablaLote(elregreso, columnas_lote) {
        this.dtOptionsLotes = {
            language: { url: '../../assets/js/spanish.json' },
            ajax: (dataTablesParameters: any, callback) => {
                let headers = new Headers({ 'Content-Type': 'application/json' })
                this.http.get(this.urlservice + 'lote/lotesconsola', { headers: headers })
                    .subscribe(resp => {
                        let lotes = resp.json().lotes;
                        callback({
                            data: lotes
                        });
                    });
            },
            columns: columnas_lote,
            rowCallback: (row: Node, data: any[] | Object, index: number) => {
                const self = this;
                // Unbind first in order to avoid any duplicate handler
                // (see https://github.com/l-lin/angular-datatables/issues/87)
                $('td', row).unbind('click');
                $('td', row).bind('click', () => {
                    self.someClickHandler(data);
                });
                return row;
            }
        }
    }
    TablaObra(elregreso, columnas_lote) {
        this.dtOptionsObras = {
            language: { url: '../../assets/js/spanish.json' },
            ajax: (dataTablesParameters: any, callback) => {
                let headers = new Headers({ 'Content-Type': 'application/json' })
                this.http.get(this.urlservice + 'obra/obras-consola', { headers: headers })
                    .subscribe(resp => {
                        let obras = resp.json().obras;
                        callback({
                            data: obras
                        });
                    });
            },
            columns: columnas_lote,
            rowCallback: (row: Node, data: any[] | Object, index: number) => {
                const self = this;
                // Unbind first in order to avoid any duplicate handler
                // (see https://github.com/l-lin/angular-datatables/issues/87)
                $('td', row).unbind('click');
                $('td', row).bind('click', () => {
                    self.someClickHandler(data);
                });
                $('td button', row).bind('click', (event) => {
                    event.stopPropagation();
                    self.buttonHandler(data, "obra_imagen");
                });
                return row;
            }
        }
    }
    TablaAlerta(elregreso, columnas_lote) {
        this.dtOptionsAlertas = {
            language: { url: '../../assets/js/spanish.json' },
            ajax: (dataTablesParameters: any, callback) => {
                let headers = new Headers({ 'Content-Type': 'application/json' })
                this.http.get(this.urlservice + 'alerta/alertas', { headers: headers })
                    .subscribe(resp => {
                        let alertas = resp.json().alertas;
                        for (let key in alertas) {
                            alertas[key].ale_fecha = alertas[key].ale_fecha.substring(0, 10);
                        }
                        callback({
                            data: alertas
                        });
                    });
            },
            columns: columnas_lote,
            rowCallback: (row: Node, data: any[] | Object, index: number) => {
                const self = this;
                // Unbind first in order to avoid any duplicate handler
                // (see https://github.com/l-lin/angular-datatables/issues/87)
                $('td', row).unbind('click');
                $('td', row).bind('click', () => {
                    self.someClickHandler(data);
                });
                $('td button', row).bind('click', (event) => {
                    event.stopPropagation();
                    self.buttonHandler(data, "obra_imagen");
                });
                return row;
            }
        }
    }

    getUrlWishList(cat_id){
        if(cat_id){
            this.perfilservice.getWishlist({cat_id: cat_id.cat_id}).subscribe(response => {
                let result = response
                if(result.valido == 1){
                    let urlfinal = this.urlservice + result.url.slice(1)
                    window.open(urlfinal,"_blank");
                }
            })
        }
    }
    someClickHandler(info) {
        info.url = this.url;
        this.rutaSelected = '';

        this._data.changeDetalleConsola(info);
        this.router.navigate(['consoladetalle/' + this.url + 'detalle']);
    }

    buttonHandler(info, tipo) {
        info.url = this.url;
        this.rutaSelected = '';

        this._data.changeDetalleConsola(info);
        this.router.navigate(['consoladetalle/consola_' + tipo + 'detalle']);
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();
    }

    CatalogoSelect() {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            dtInstance.destroy();
        });
    }

}  